import { ConstructorType } from '@/services/filters/classes/types';
import { BaseFilter } from '@/services/filters/classes/base.filter';
import { FilterDateParser } from '@/services/filters/dateParser';
import { IFilter } from '@/types';

export class DatepickerFilter extends BaseFilter {
    datepicker?: boolean = true;
    pickerType: 'date' | 'month';
    defaultValue?: string[] | string | Date[];
    range?: boolean;
    isTimeAdder?: boolean;
    defaultTime?: string;
    periodTemplate?: string;

    constructor(config: ConstructorType<DatepickerFilter, 'datepicker' | 'pickerType'>) {
        super(config);
        this.range = config.range;
        this.select = config.select ?? (this.range ? [] : '');
        this.pickerType = config.pickerType;
        this.defaultValue = config.defaultValue ?? [];
        this.isTimeAdder = config.isTimeAdder;
        this.defaultTime = config.defaultTime;
        this.periodTemplate = config.periodTemplate;
    }

    clear(): void {
        super.clear();
        this.select = this.range ? this.defaultValue || [] : this.defaultValue || '';
    }

    parseQueryAndGetChanges(query: any, currentQueryKey: string): Record<string, any> {
        const queryValue = query[currentQueryKey];

        if (!this.range && currentQueryKey === this.id) {
            this.select = queryValue;
            return { [currentQueryKey]: this.select };
        } else if (currentQueryKey === `${this.id}_from` && Array.isArray(this.select)) {
            if (this.defaultValue?.length) {
                this.select = [];
            }

            if (queryValue === query[`${this.id}_to`]) {
        this.select!.push(queryValue);
        this.select!.push(query[`${this.id}_to`]);
        return {
            [currentQueryKey]: queryValue,
            [`${this.id}_to`]: query[`${this.id}_to`],
        };
            }

      this.select!.push(queryValue);
      return { [currentQueryKey]: queryValue };
        } else if (currentQueryKey === `${this.id}_to` && queryValue !== query[`${this.id}_from`] && Array.isArray(this.select)) {
      this.select!.push(queryValue);
      return { [currentQueryKey]: queryValue };
        }

        return {};
    }

    getValueForSubmit(filter: IFilter = this): Record<string, any> {
        return new FilterDateParser({ [filter.id]: filter.select })!.parse(filter);
    }
}
