import { CreatedFilters } from "@/services/filters/filters";
import { IColumnFilter, IFilter, TableHeader } from "@/types";
import { filterClass } from "@/services/filters/filterClass";
import { getAffiliateSearch, getUserSearch } from "@/api/user";
import { translateLeadStatus, translateMyLeadgidInvoiceStatus } from "@/utils/translate";
import { getEmployeeMethods } from "@/api/payout";
import { showServerError } from "@/utils";
import { CreatedColumns } from "@/services/columns/columns";
import { IMyLeadgidInvoice } from "@/api/types/payout";
import { getFormatDate } from "@/utils/formatDates";
import SvgMasterInvoice from "@/assets/icons/master-invoice.svg";
import { ITdRoute } from "@/components/base/TableUI/TdRoute.vue";

export class AffiliateInvoicesFilters extends CreatedFilters {
    constructor() {
        super();

        (async () => await this.setEmployeeMethods())();
    }

    getFilters(): IFilter[] {
        return [
            new filterClass.ComboboxArray({
                id: 'invoice_ids',
                label: 'ID счёта',
                select: [],
                checkMultiPaste: true,
                multiPaste: true,
            }),
            new filterClass.Autocomplete({
                id: 'master_ids',
                apiMethod: getAffiliateSearch,
                label: 'Мастер-аккаунт',
                itemValue: 'id',
                itemText: 'name',
                select: [],
                items: [],
                multiple: true,
                multiPaste: true,
                alsoSearchBy: 'email',
                getValueForSubmit(filter: IFilter) {
                    return {
                        master_account_id: filter.select.map((item) => item.id),
                    };
                },
            }),
            new filterClass.Autocomplete({
                id: 'affiliate_id',
                apiMethod: getAffiliateSearch,
                label: 'Партнёр',
                itemValue: 'id',
                itemText: 'name',
                template: (i) => `${i.id} ${i.first_name} ${i.last_name} ${i.email}`,
                select: [],
                items: [],
                multiple: true,
                multiPaste: true,
                getValueForSubmit(filter: IFilter) {
                    return {
                        [filter.id]: filter.select.map((item) => item.id),
                    };
                },
            }),
            new filterClass.Multiselect({
                id: 'currency',
                label: 'Валюта',
                noDataText: 'Нет выбранной валюты',
                items: [],
                itemValue: 'code',
                itemText: 'code',
                type: 'string',
                isCurrencyCatalogue: true,
            }),
            new filterClass.Multiselect({
                id: 'paid_status',
                type: 'string',
                label: 'Статус',
                items: [
                    { id: 'paid' },
                    { id: 'not_paid' },
                    { id: 'partial_paid' },
                ],
                select: [],
                template: (i) => translateMyLeadgidInvoiceStatus(i.id).text,
                itemText: (i) => translateMyLeadgidInvoiceStatus(i.id).text,
                itemValue: 'id',
            }),
            new filterClass.Autocomplete({
                id: 'manager_id',
                apiMethod: getUserSearch,
                label: 'Менеджер',
                itemValue: 'id',
                itemText: 'name',
                select: '',
                items: [],
                remove: true,
                multiple: false,
                getValueForSubmit(filter: IFilter) {
                    return {
                        [filter.id]: filter.select.id,
                    };
                },
            }),
            new filterClass.Multiselect({
                id: 'current_method_ids',
                type: 'string',
                label: 'Текущий способ выплаты',
                noDataText: 'Нет выбранного способа выплаты',
                select: [],
                items: [],
                itemValue: 'id',
                itemText: (i) => i.name.ru,
                template: (i) => i.name.ru,
            }),
            new filterClass.Multiselect({
                // WARNING: Не уверен, что ничего не поломаю т.к надо срочно поменять позже удалю  / paid_by_payment_method_ids -> method_invoice_was_paid_ids
                id: 'paid_by_payment_method_ids',
                type: 'string',
                label: 'Способ выплаты',
                noDataText: 'Нет выбранного способа выплаты',
                select: [],
                items: [],
                itemValue: 'id',
                itemText: (i) => i.name.ru,
                template: (i) => i.name.ru,
            }),
            new filterClass.Datepicker({
                id: 'period',
                label: 'Период оплаты',
                pickerType: 'date',
                select: [],
                range: true,
                clearable: true,
                allowedDates: () => ({ start: null, end: new Date(), days: [1, 15] }),
            }),
            new filterClass.Checkbox({
                label: 'Мастер-счёт',
                id: 'is_master',
                select: [],
                itemText: 'false',
                type: 'string',
            }),
        ];
    }

    async setEmployeeMethods(): Promise<void> {
        try {
            const currentMethodFilter = this.filters.find(f => f.id === 'current_method_ids')!;
            // WARNING: Не уверен, что ничего не поломаю т.к надо срочно поменять позже удалю  / paid_by_payment_method_ids -> method_invoice_was_paid_ids
            const wasPaidFilter = this.filters.find(f => f.id === 'paid_by_payment_method_ids')!;
            if (currentMethodFilter?.items?.length! > 0 && wasPaidFilter?.items?.length!) return;
            const { data } = await getEmployeeMethods();
            wasPaidFilter.items = data;
            currentMethodFilter.items = data;
        } catch (err) {
            showServerError(err, 'Список способов выплат не загружен');
        }
    }
}

export class AffiliateInvoicesColumns extends CreatedColumns {
    constructor() {
        super();
    }

    getColumns(): IColumnFilter[] {
        return [
            {
                value: false,
                name: 'Счета',
                items: [
                    { title: 'Партнёр', value: 'affiliate_id' },
                    { title: 'Менеджер', value: 'manager_id' },
                    { title: 'Мастер-аккаунт', value: 'master_id' },
                    { title: 'Доход', value: 'total_revenue' },
                    { title: 'Выплата', value: 'total_amount' },
                    { title: 'Выплачено', value: 'partials_amount' },
                    { title: 'Остаток', value: 'rest_amount' },
                    { title: 'Прибыль', value: 'total_profit' },
                    { title: 'Период оплаты', value: 'pay_period' },
                    { title: 'Дата создания', value: 'created_at' },
                    { title: 'Дата обновления', value: 'updated_at' },
                    { title: 'Статус', value: 'paid_status' },
                ],
            },
            {
                id: 'payment',
                value: false,
                name: 'Куда была оплата',
                items: [
                    { title: 'Способ выплаты', value: 'method' },
                    { title: 'Реквизиты', value: 'requisites' },
                ],
            },
            {
                id: 'current_payment',
                value: false,
                name: 'Текущий способ выплаты',
                items: [
                    { title: 'Способ выплаты', value: 'current_payment_method.name.ru' },
                    { title: 'Реквизиты', value: 'current_payment_method.requisite' },
                ],
            },
        ];
    }
}

export const AffiliateInvoicesHeaders: TableHeader[] = [
    { text: 'ID счёта', value: 'id', align: 'center', width: 104, fixed: true },
    { text: 'Партнёр', value: 'affiliate_id', align: 'left'  },
    { text: 'Менеджер', value: 'manager_id', width: 130 },
    { text: 'Мастер-аккаунт', value: 'master_id' },
    { text: 'Валюта', value: 'currency', fixed: true },
    { text: 'Доход', value: 'total_revenue', align: 'right' },
    { text: 'Выплата', value: 'total_amount', align: 'right' },
    { text: 'Выплачено', value: 'partials_amount', align: 'right' },
    { text: 'Остаток', value: 'rest_amount', align: 'right' },
    { text: 'Прибыль', value: 'total_profit', align: 'right' },
    { section: 'Куда была оплата', text: 'Способ выплаты', value: 'method', sectionDescription: 'Основной способ выплаты партнёра на момент оплаты счёта' },
    { section: 'Куда была оплата', text: 'Реквизиты', value: 'requisites' },
    { section: 'Текущий способ выплаты', text: 'Способ выплаты', value: 'current_payment_method.name.ru' },
    { section: 'Текущий способ выплаты', text: 'Реквизиты', value: 'current_payment_method.requisite' },
    { text: 'Период оплаты', value: 'pay_period', align: 'right' },
    { text: 'Дата создания', value: 'created_at', width: 90, align: 'right' },
    { text: 'Дата обновления', value: 'updated_at', width: 90, align: 'right' },
    { text: 'Статус', value: 'paid_status' },
];

export const AffiliateInvoicesRoutes: ITdRoute[] =  [
    {
        routeParam: 'id',
        slot: 'item.id',
        routerName: 'affiliate-invoice',
        text: 'id',
        asideIcon: SvgMasterInvoice,
        showIcon: (item: IMyLeadgidInvoice): boolean => !!item.is_master,
        tooltip: (item: IMyLeadgidInvoice): string => item.is_master ? 'Мастер-счёт' : '',
        tooltipClass: 'base-tooltip',
    },
    {
        routeParam: 'id',
        extraRouteParamName: 'affiliate_id',
        slot: 'item.affiliate_id',
        routerName: 'affiliate',
        text: 'affiliate_id',
        template: (item: IMyLeadgidInvoice): string | number =>  item.affiliate ? `${item.affiliate_id || ''} ${item.affiliate || ''}` : item.affiliate_id || '',
    },
    {
        routeParam: 'id',
        extraRouteParamName: 'master_id',
        slot: 'item.master_id',
        routerName: 'affiliate',
        text: 'master_id',
    },
];

export const AffiliateInvoicesStatuses = [
    {
        slot: 'item.paid_status',
        key: 'paid_status',
        translate: translateMyLeadgidInvoiceStatus,
    },
    {
        slot: 'item.is_paid',
        key: 'is_paid',
        translate: translateLeadStatus,
    },
];

export const AffiliateInvoicesUniqueData = [
    {
        slot: 'item.manager_id',
        template:  (item: IMyLeadgidInvoice): string => item.name || '',
    },
    {
        slot: 'item.partials',
        template: (item: IMyLeadgidInvoice): string => item.partials.reduce((total, i) => total + i.amount, 0).toLocaleString('ru-RU',
            { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
    },
    {
        slot: 'item.rest_amount',
        template: (item: IMyLeadgidInvoice): string => item.rest_amount.toLocaleString('ru-RU',
            { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
    },
    {
        slot: 'item.method',
        template: (item: IMyLeadgidInvoice): string => item.paid_by_payment_method ? item.paid_by_payment_method.name.ru : '',
    },
    {
        slot: 'item.pay_period',
        template: (item: IMyLeadgidInvoice): string => getFormatDate(item?.pay_period, 'd MMMM yyyy'),
    },
    {
        slot: 'item.data-table-select',
    },
];

export const AffiliateInvoicesNumberFormatting = [
    {
        slot: 'item.total_revenue',
        key: 'total_revenue',
        isMono: true,
    },
    {
        slot: 'item.total_amount',
        key: 'total_amount',
        isMono: true,
    },
    {
        slot: 'item.partials_amount',
        key: 'partials_amount',
        isMono: true,
    },
    {
        slot: 'item.total_profit',
        key: 'total_profit',
        isMono: true,
    },
    {
        slot: 'item.rest_amount',
        key: 'rest_amount',
        isMono: true,
    },
];

export const AffiliateInvoicesTimeData =  [
    {
        slot: 'item.created_at',
        key: 'created_at',
        isLine: false,
    },
    {
        slot: 'item.updated_at',
        key: 'updated_at',
        isLine: false,
    },
];
