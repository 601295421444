import { IPostOfferGoal } from "@/api/types/offers";
import { IOfferFiled, OFFER_FIELD, CREATE_OFFER_VALIDATE_RULES } from "@/services/offers/CreateOffer";
import { showServerError } from "@/utils";
import { createOfferGoal } from "@/api/offers";

export class CreateGoal implements IPostOfferGoal {
    id: number = 0;
    name: string = '';
    is_private: boolean = false;
    revenue_type: 'fixed' | 'percent'  = 'fixed';
    revenue: number = 0;
    revenue_model: string = 'cpc';
    payout_type: 'fixed' | 'percent' = 'fixed';
    payout: number = 0;
    payout_model: string = 'cpc';
    status: string = 'active';
    is_endpoint: boolean = false;
    is_approve_conversions: boolean = false;
    protocol: string = 'postback';

    constructor(goal: IPostOfferGoal, id: number) {
        this.id = id;
        this.name = goal.name || '';
        this.is_private = goal.is_private || false;
        this.revenue_type = goal.revenue_type || 'fixed';
        this.revenue = goal.revenue || 0;
        this.revenue_model = goal.revenue_model || 'cpc';
        this.payout_type = goal.payout_type || 'fixed';
        this.payout = goal.payout = 0;
        this.payout_model = goal.payout_model = 'cpc';
        this.status = goal.status || 'active';
        this.is_endpoint = goal.is_endpoint || false;
        this.is_approve_conversions = goal.is_approve_conversions || false;
        this.protocol = goal.protocol || 'postback';
    }

    get getOptions(): IOfferFiled[] {
        return [
            { text: 'Название', property: 'name', type: OFFER_FIELD.TEXT, validateRule: CREATE_OFFER_VALIDATE_RULES.TEXT },
            { text: 'Приватность', property: 'is_private', type: OFFER_FIELD.SWITCH },
            { text: 'Доход', property: 'revenue_type', type: OFFER_FIELD.SELECT },
            { text: '', property: 'revenue', type: OFFER_FIELD.MONEY },
            { text: 'Выплата', property: 'payout_type', type: OFFER_FIELD.SELECT },
            { text: '', property: 'payout', type: OFFER_FIELD.MONEY },
        ];
    }

    get isDisableCreate(): boolean {
        return !this.getOptions.every(field =>
            field.validateRule === undefined ? true : field.validateRule(this[field.property]));
    }

    async createGoal(offer_id: number): Promise<void> {
        try {
            const dto = Object.fromEntries(Object.entries(this));
            dto.protocol = 'postback';
            await createOfferGoal(offer_id, dto as IPostOfferGoal);
        } catch (err) {
            showServerError(err, 'Ошибка создания цели');
            throw new Error(err);
        }
    }
}
