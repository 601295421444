
import { Component, InjectReactive, Prop, Vue } from "vue-property-decorator";
import SvgArrow from "@/assets/icons/arrow-down.svg";
import { IAffiliateHistoryItem } from "@/api/types/user";
import {
    translateLang,
    translatePartnerStatus,
    translatePaymentsRequisite,
    translatePayoutsRequestStatus,
} from "@/utils/translate";
import { translateAffiliateField } from "@/services/affiliates/Affiliate";
import { getFormatDate } from "@/utils/formatDates";
import { ICountry } from "@/api/types/catalogue";
import { namespace } from "vuex-class";
import { IEmployeeAffiliateMethod } from "@/api/types/payout";

const catalogue = namespace('catalogueModule');
@Component({
    methods: { translateAffiliateField },
    components: { SvgArrow },
})

export default class AffiliateChangeHistoryItem extends Vue {
    @Prop({ required: true }) item!: IAffiliateHistoryItem;
    @Prop({ required: true }) field!: 'old_value' | 'new_value';

    @catalogue.Getter('GET_COUNTRIES') countries!: ICountry[];

    @InjectReactive() readonly getAffiliatePayments!: IEmployeeAffiliateMethod[];

    isShowMenu: boolean = false;

    get isRoute(): boolean {
        const fields = ['slaves', 'master'];
        return fields.includes(this.item.field);
    }

    get isShowDropdown(): boolean {
        const fields = [ 'all', 'messengers'];
        return fields.includes(this.item.field) && this.item[this.field] !== '';
    }

    get isShowArray(): boolean {
        const fields = [ 'traffic_sources', 'tags', 'verticals'];
        return fields.includes(this.item.field) && this.item[this.field] !== '';
    }

    get getWidth(): string {
        return this.$vuetify.breakpoint.width <= 769 ? '100vw' : '700px';
    }
    get getHeight(): string {
        return this.$vuetify.breakpoint.width <= 769 ? '100vh' : '550px';
    }

    get getPreviewText(): string {
        if (!this.isShowDropdown) return '';
        const text = 'Раскрыть';
        return text.replace(/(<([^>]+)>)/gi, '').slice(0, 35) + '...';
    }

    transformArrayToObject(messengers: any): object {
        return messengers.reduce((acc, messenger) => {
            const [typeKey, accountKey] = Object.keys(messenger);
            acc[messenger[typeKey]] = messenger[accountKey];
            return acc;
        }, {});
    }

    getItemsField(items: any): any {
        if (Array.isArray(items)) {
            return this.transformArrayToObject(items);
        } else {
            typeof items === 'object' && delete items.id;
            return items;
        }
    }

    translateArrays(rawValue?: string[] | number[]): string {
        const value = rawValue || this.item[this.field] as string[] | number[];
        return value.join(', ');
    }

    getValue(value: any, key: string): any {
        let prepareValue: any = value;
        if (key === 'image') {
            prepareValue = `<a href="${value}">${value}</a>`;
        } else if (key === 'messengers') {
            prepareValue = this.transformArrayToObject(value);
        } else if (key === 'keyName') {
            prepareValue = translateAffiliateField(value);
        } else if (key.includes('birthday') && value) {
            prepareValue = this.translateValue(value.date || value, key);
        } else if (key === 'country') {
            prepareValue = this.countries.find((item: ICountry) => item.code === value)?.name;
        } else if (key === 'methodSetting') {
            prepareValue = this.getAffiliatePayments.find((item: IEmployeeAffiliateMethod) => item.method_id === value)?.method_name?.ru;
        } else if (Array.isArray(value)) {
            prepareValue = this.translateArrays(value);
        } else {
            prepareValue = this.translateValue(value, key, this.item.subject);
        }

        if (prepareValue && typeof prepareValue === 'object') {
            const translateMethod = key === 'partnerData' ? translatePaymentsRequisite : translateAffiliateField;
            return Object.entries(prepareValue).map(([key, value]) => `\n\t ${translateMethod(key)} : ${this.getValue(value, key)}`).join(', ');
        }
        return prepareValue;
    }

    translateSingleValue(value: string | boolean): string {
        switch (value) {
        case 'keyName': return 'Партнёр';
        case 'value': return 'Создание';
        case 'enabled': return 'Включено';
        case 'disabled': return 'Выключено';
        case '1': return 'Да';
        case true: return 'Да';
        case false: return 'Нет';
        case 'light': return 'Светлая';
        case 'dark': return 'Тёмная';
        default: return value;
        }
    }

    translateValue(value: string, key: string, subject?: string): string {
        switch (key) {
        case 'status': return subject === 'PartnerMethod' ? translatePayoutsRequestStatus(value)?.text : translatePartnerStatus(value)?.text || value;
        case 'language': return translateLang(value) || value;
        case 'cardholder_birthday':
        case 'birthday': return getFormatDate(value, 'dd MMMM yyyy');
        case 'country': return this.getValue(value, key);
        default: return this.translateSingleValue(value);
        }
    }
}
