
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import { getFormatDate } from "@/utils/formatDates";
import { eventBus } from "@/eventbus";
import Status from "@/components/base/Status.vue";
import BaseSelect from "@/components/base/design/BaseSelect.vue";
import { translateBoolean, translateTwoFactor, translateUserStatus, translateUserType } from "@/utils/translate";
import { IUserSettings, IUserV3 } from "@/api/types/user";
import { massChangeAffiliatesStatus, patchUserByTypeV3 } from "@/api/user";
import { showServerError } from "@/utils";
import { namespace } from 'vuex-class';
import BaseCard from "@/components/base/BaseCard.vue";
import { USER_ROLES } from "@/mappings/user-roles";
import { ACCOUNT_TYPE } from "@/mappings/account-type";

const auth = namespace('authModule');

@Component({
    methods: { translateTwoFactor, translateBoolean },
    components: {
        BaseCard,
        BaseSelect,
        Status,
    },
})
export default class UserCardMain extends Vue {
    @Prop({ required: true }) user!: IUserV3;
    @PropSync('isLoading') isLoadingSync!: boolean;
    @auth.Getter('GET_USER') currentUser;
    @auth.Mutation('SET_TWO_FACTORS_STATUS') setTwoFactorsStatus;
    getFormatDate = getFormatDate;
    statusItems = [{ id: 'active' }, { id: 'deleted' }];
    isEdit = false;
    userData: any = {
        status: '',
        is_two_factor_enabled: '',
        first_name: '',
        last_name: '',
    };
    userSettings = {} as IUserSettings;

    get isEditableFields(): boolean {
        return [ACCOUNT_TYPE.ADVERTISER, ACCOUNT_TYPE.EMPLOYEE, ACCOUNT_TYPE.SYSTEM, ACCOUNT_TYPE.AFFILIATE].includes(this.user.account_type as any);
    }

    get getStatusItems(): { id: string, text: string }[] {
        return this.statusItems.map(i => {
            return { id: i.id, text: translateUserStatus(i.id).text };
        });
    }

    get getOptions(): any[] {
        const options = [
            {
                name: 'Имя',
                value: this.user.first_name,
                type: 'text',
                isEditable: this.isEditableFields,
                editValue: this.userData.first_name,
                update: (e) => this.userData.first_name = e,
            },
            {
                name: 'Почта',
                value: this.user.email,
                type: 'text',
                isEditable: false,
            },
            {
                name: 'Фамилия',
                value: this.user.last_name,
                type: 'text',
                isEditable: this.isEditableFields,
                editValue: this.userData.last_name,
                update: (e) => this.userData.last_name = e,
            },
            {
                name: 'Статус',
                value: this.user.status,
                status: translateUserStatus,
                type: 'status',
                isEditable: this.isEditableFields,
                editValue: this.userData.status,
                items: this.getStatusItems,
                update: (e) => this.userData.status = e,
            },
            {
                name: 'User ID',
                value: this.getUserIdValue,
                type: 'text',
                isEditable: false,
            },
            {
                name: '2fa',
                value: this.user.two_factor_enabled,
                isEditable: true,
                type: 'boolean',
                editValue: this.userData.is_two_factor_enabled,
                status: translateTwoFactor,
                isDisabled: !this.user.two_factor_enabled || !this.isAdmin,
                update: (e) => this.userData.is_two_factor_enabled = e,
            },
            {
                name: 'Account ID',
                value: this.getAccountIdValue,
                type: 'text',
                isEditable: false,
            },
            {
                isOnlyEmployee: true,
                name: 'Назначить новорегов Ru',
                value: Array.isArray(this.user.settings) ? 'disabled' : this.user.settings?.assign_ru_registrations!,
                isEditable: true,
                type: 'boolean',
                trueValue: 'enabled',
                falseValue: 'disabled',
                editValue: this.userSettings.assign_ru_registrations,
                update: (e) => this.userSettings.assign_ru_registrations = e,
                class: '--long-item',
            },
            {
                name: 'Тип',
                value: translateUserType(this.user.account_type).text,
                type: 'text',
                isEditable: false,
            },
            {
                isOnlyEmployee: true,
                name: 'Назначить новорегов En',
                value: Array.isArray(this.user.settings) ? 'disabled' : this.user.settings?.assign_en_registrations!,
                isEditable: true,
                type: 'boolean',
                trueValue: 'enabled',
                falseValue: 'disabled',
                editValue: this.userSettings.assign_en_registrations,
                update: (e) => this.userSettings.assign_en_registrations = e,
                class: '--long-item',
            },
        ];

        if (this.user.account_type === ACCOUNT_TYPE.EMPLOYEE) {
            return options;
        }
        return options.filter(i => !i.isOnlyEmployee);
    }

    get getUserIdValue(): number {
        return [ACCOUNT_TYPE.ADVERTISER, ACCOUNT_TYPE.SYSTEM].includes(this.user.account_type) ? this.user.id! : this.user.user_id!;
    }
    get getAccountIdValue(): number {
        return [ACCOUNT_TYPE.ADVERTISER, ACCOUNT_TYPE.SYSTEM].includes(this.user.account_type) ? this.user.account_id! : this.user.id!;
    }

    get isChange2fa(): boolean {
        return !!this.userData.is_two_factor_enabled !== !!this.user.two_factor_enabled;
    }

    get isSettingsChanged(): boolean {
        return this.user.settings ? JSON.stringify(this.userSettings) !== JSON.stringify(this.user.settings) : false;
    }

    get isAdmin(): boolean {
        return this.currentUser.roles.includes(USER_ROLES.ADMIN);
    }

    editInfo(edit: boolean): void {
        if (edit) {
            this.setDefaults();
        } else {
            this.updateAllUserData();
        }
        this.isEdit = edit;
    }

    cancelEdit(): void {
        this.isEdit = false;
    }

    setDefaults(): void {
        this.userData.status = this.user.status;
        this.userData.is_two_factor_enabled = !!this.user.two_factor_enabled;
        this.userData.first_name = this.user.first_name;
        this.userData.last_name = this.user.last_name;

        if (Array.isArray(this.user.settings)) {
            this.userSettings = { assign_ru_registrations: 'disabled', assign_en_registrations: 'disabled' };
        } else if (this.user.settings) {
            this.userSettings = JSON.parse(JSON.stringify(this.user.settings));
        }
    }

    async updateAllUserData(): Promise<void> {
        this.isLoadingSync = true;
        try {
            if (this.isChange2fa) {
                const data = { disable_2fa: !this.userData.is_two_factor_enabled };
                const id = this.user.account_type === ACCOUNT_TYPE.ADVERTISER ? this.user.account_id! : this.user.id!;
                await patchUserByTypeV3(id, this.user.account_type!, data);
                if (!this.userData.is_two_factor_enabled) {
                    this.setTwoFactorsStatus(false);
                }
            }
            if (this.isSettingsChanged) {
                const data: {settings: {key: string; value: string}[]} = { settings: [] };
                for (const key in this.userSettings) {
                    const item = { key, value: this.userSettings[key] };
                    data.settings.push(item);
                }
                await patchUserByTypeV3(this.user.id as number, this.user.account_type!, data);
            }
            const editableItems = this.getOptions.filter(i => i.isEditable && i.name !== '2fa');
            if (editableItems.some(i => i.value !== i.editValue)) {
                const data = Object.assign({}, this.userData);
                delete data.is_two_factor_enabled;
                const id = this.user.account_type === ACCOUNT_TYPE.ADVERTISER ? this.user.account_id : this.user.id;
                await patchUserByTypeV3(id as number, this.user.account_type as any, data);
            }
            // статус у партнера меняется по другому урлу
            if (this.userData.status !== this.user.status && this.user.account_type === ACCOUNT_TYPE.AFFILIATE) {
                const data = { affiliate_ids: [this.user.id as number], status: this.userData.status };
                await massChangeAffiliatesStatus(data);
            }
            eventBus.$emit("update-user");

        } catch (err) {
            showServerError(err, 'Ошибка сохранения');
        } finally {
            this.isLoadingSync = false;
        }
    }
}
