
import { Vue, Component, Prop } from "vue-property-decorator";
import AppTableDesign from "@/components/base/table/AppTableDesign.vue";
import { ITableStatus, TableHeader } from "@/types";
import { Affiliate } from "@/services/affiliates/Affiliate";
import ItemTableList from "@/components/base/TableUI/ItemTableList.vue";
import { translateTwoFactor, translateUserStatus, translateUserType } from "@/utils/translate";
import { IUser } from "@/api/types/user";

@Component({
    components: { ItemTableList, AppTableDesign },
})
export default class UsersTable extends Vue {
    @Prop({ required: true }) items!: Affiliate[];
    @Prop({ default: 100 }) limit!: number;
    @Prop({ default: 0 }) total!: number;
    @Prop({ default: true }) loading!: boolean;
    @Prop({ default: false }) isDetailPage!: boolean;

    headers: TableHeader[] = [
        { text: 'User ID', value: 'id', align: 'center', width: '120px', fixed: true },
        { text: 'Account ID', value: 'account_id', align: 'center', width: '120px', fixed: true },
        { text: 'Имя', value: 'first_name', fixed: true },
        { text: 'Фамилия', value: 'last_name', fixed: true },
        { text: 'Почта', value: 'email', fixed: true },
        { text: 'Тип', value: 'account_type', fixed: true },
        { text: '2fa', value: 'two_factor_enabled', align: 'center', fixed: true },
        { text: 'Статус', value: 'status', align: 'center', fixed: true },
    ];

    routes = [
        {
            routeParam: 'id',
            slot: 'item.id',
            extraRouteParamName: 'account_id',
            routerName: 'user-detail-page',
            text: 'id',
            getQuery(item: {id: number, account_type: string}): {id: number, account_type: string} {
                return {
                    id: item.id,
                    account_type: item.account_type!,
                };
            },
        },
        {
            routeParam: 'id',
            extraRouteParamName: 'account_id',
            slot: 'item.account_id',
            routerName: 'user-detail-page',
            text: 'account_id',
            getQuery(item: {id: number, account_type: string}): {id: number, account_type: string} {
                return {
                    id: item.id,
                    account_type: item.account_type!,
                };
            },
        },
    ];

    statuses: ITableStatus[] = [
        {
            slot: 'item.status',
            key: 'status',
            translate: translateUserStatus,
        },
        {
            slot: 'item.two_factor_enabled',
            key: 'two_factor_enabled',
            translate: translateTwoFactor,
        },
    ];

    uniqueData = [
        {
            slot: 'item.account_type',
            template: (item: IUser): string => translateUserType(item.account_type).text,
        },
    ];

    submitData(offset: number, limit: number): void {
        this.$emit('update-table', offset, limit);
    }
}
