
import { Component, Vue, Prop, PropSync } from 'vue-property-decorator';
import DateRange from '@/components/base/pickers/DateRange.vue';
import Search from '@/components/base/Search.vue';
import SvgFileDownloaded from '@/assets/icons/download.svg';
import SvgUpdate from '@/assets/icons/update.svg';
import SelectDesign from "@/components/base/form/SelectDesign.vue";
import { IFilter } from "@/types";
import CurrencyConversion from "@/components/statistics/extended-report/CurrencyConversion.vue";
import ReportFilterByDate from "@/components/statistics/extended-report/ReportFilterByDate.vue";

@Component({
    components: {
        ReportFilterByDate,
        CurrencyConversion,
        SelectDesign,
        DateRange,
        Search,
        SvgFileDownloaded,
        SvgUpdate,
    },
})

export default class ReportLayout extends Vue {
    @Prop({ type: [Number, String], default: 0 }) elevation!: number | string;
    @Prop({ type: String, required: true }) titleReport!: string;
    @Prop({ type: Array, required: true }) filters!: any[];
    @Prop({ type: Boolean, default: false }) readonly isDisabledSubmit!: boolean;
    @Prop({ type: Boolean, default: true }) readonly isSearch!: boolean;
    @Prop({ type: Boolean, default: false }) readonly isCurrencyConversion!: boolean;
    @Prop({ type: Boolean, default: false }) readonly isProgressLoading!: boolean;
    @Prop({ default: 0 }) readonly progress!: number;
    @Prop({ type: Array, default: () => ([]) }) readonly exportFormats!: [];
    @PropSync('settings', { type: Object, required: true }) settingsSync!: {
        loading: boolean;
        searchPlaceholder: string;
        title: string;
        loadingExport: boolean;
        loadingUpdate: boolean;
        convertCurrency: string;
    };
    @Prop({ type: Boolean, default: true }) isShowTitle!: boolean;

    search = '';

    get loadingDownloadBtn(): boolean {
        return this.isProgressLoading ? this.progress < 100 && this.settingsSync.loadingExport : this.settingsSync.loadingExport;
    }

    get groupFilter(): IFilter {
        return this.filters.find(i => i.id === 'group');
    }

    updateTable(): void {
        this.settingsSync.loadingUpdate = true;
        this.$emit('update-table');
    }

    selectCurrencyConversion(): void {
        this.$emit('change-cur-conversion');
    }
}
