
import { Vue, Component, PropSync, Prop } from 'vue-property-decorator';
import BaseChip from "@/components_v3/base/formComponents/BaseChip.vue";

@Component({
    components: { BaseChip },
})
export default class BaseCombobox extends Vue {
    @Prop({ default: false }) isMultiple!: boolean;
    @Prop({ default: false }) isDisabled!: boolean;
    @Prop({ default: false }) isUnderline!: boolean;
    @Prop({ default: 38 }) height!: number;
    @Prop({ default: 3 }) maxItems!: number;
    @PropSync('value') valueSync!: string[] | string;

    items: string[] = [];
    inputValue: string = '';
    isShowDropdown: boolean = false;

    get getStyle(): string {
        const height = `min-height: ${this.height}px;`;

        return `${height}`;
    }

    get getClasses(): string[] {
        const classes = {
            '--underline': this.isUnderline,
            '--disabled': this.isDisabled,
        };
        return Object.keys(classes).filter((key) => classes[key]);
    }

    mounted(): void {
        this.validateValue();
    }

    validateValue(): void {
        if (Array.isArray(this.valueSync)) {
            this.valueSync = this.valueSync.filter(Boolean);
        }
    }

    addItem(): void {
        if (!this.inputValue) return;
        this.addItemHandler();
    }

    addItemHandler(item: string = this.inputValue): void {
        if (this.isMultiple) {
            this.addItemToValue(item);
        } else {
            this.valueSync = this.inputValue;
        }
    }

    addItemToValue(item: string): void {
        const isChecked = this.valueSync.includes(item);
        if (isChecked) return;
        const isExist = this.items.includes(item);
        if (!isExist) this.items.push(item);
        this.valueSync = [...this.valueSync, item];
        this.inputValue = '';
    }

    deleteSelectedItem(item: string): void {
        if (!this.isMultiple || !Array.isArray(this.valueSync)) return;
        const index = this.valueSync.findIndex(i => i === item);
        if (index < 0) return;
        this.valueSync.splice(index, 1);

        if (this.valueSync.length === 0) {
            this.closeDropdown();
        }
    }

    toggleDropdown(): void {
        this.isShowDropdown = !this.isShowDropdown;
    }

    blurInput(): void {
        this.addItem();
    }

    closeDropdown(): void {
        this.isShowDropdown = false;
    }
}
