
import { Component, Vue, Prop } from 'vue-property-decorator';
import { showServerError } from '@/utils';
import { getRateUtil } from '@/utils/personalRate/rate';
import { IPersonalRatesBaseFinance, IRatesHistory } from '@/api/types/offers';
import { deletePersonalRateTask } from '@/api/offers';
import SvgOfferList from '@/assets/icons/nav-bar/offer-list.svg';
import { namespace } from 'vuex-class';
import TableWithFrontPaginationDesign from "@/components/base/table/TableWithFrontPaginationDesign.vue";
import { translateRatesHistory } from "@/utils/translate";
import BaseCard from "@/components/base/BaseCard.vue";
import { NotificationMessage } from "@/utils/notification";

const catalogue = namespace('catalogueModule');

@Component({
    components: {
        TableWithFrontPaginationDesign,
        SvgOfferList,
        BaseCard,
    },
})
export default class PersonalRateHistory extends Vue {
    @Prop( { required: true }) ratesHistory!: IRatesHistory[];
    @Prop( { required: true }) settings!: { title:string, loading: boolean };

    @catalogue.Getter('GET_CURRENT_CURRENCY_ICON') currencyIcon;

    headers = [
        { text: 'Действие', sortable: false, value: 'action' },
        { text: 'Дата изменения', sortable: true, value: 'date' },
        { text: 'Начало действия', sortable: false, value: 'action_date' },
        { section: 'Доход', text: 'Старое значение', sortable: false, value: 'old_revenue', align: 'right' },
        { section: 'Доход', text: 'Новое значение', sortable: false, value: 'new_revenue', align: 'right' },
        { section: 'Выплата', text: 'Старое значение', sortable: false, value: 'old_payout', align: 'right' },
        { section: 'Выплата', text: 'Новое значение', sortable: false, value: 'new_payout', align: 'right' },
        { text: 'Кем изменено', sortable: false, value: 'employee_name' },
        { sortable: false, value: 'actions', align: 'center' },
    ];

    timeData = [
        {
            slot: 'item.date',
            key: 'date',
            isSeconds: true,
        },
        {
            slot: 'item.action_date',
            key: 'action_date',
        },
    ];

    uniqueData = [
        {
            slot: 'item.old_revenue',
            template: (item: IRatesHistory): string => this.getRate(item.old_revenue),
        },
        {
            slot: 'item.new_revenue',
            template: (item: IRatesHistory): string => this.getRate(item.new_revenue),
        },
        {
            slot: 'item.old_payout',
            template: (item: IRatesHistory): string => this.getRate(item.old_payout),
        },
        {
            slot: 'item.new_payout',
            template: (item: IRatesHistory): string => this.getRate(item.new_payout),
        },
        {
            slot: 'item.action',
            template: (item: IRatesHistory): string => translateRatesHistory(item.action),
        },
    ];

    actions = [
        {
            title: 'Удалить',
            color: '#1B1B1D',
            icon: 'SvgTrashWhite',
            disabled: (item: any): boolean => {
                return this.isShowTrashBtn(item);
            },
            callback: ({ id }: { id: number }): void => {
                this.removeItem(id);
            },
        },
    ];

    getRate(item: IPersonalRatesBaseFinance): string {
        const currentCurrency = this.currencyIcon(item.currency);
        const fixedAmount = item.amount.toLocaleString('ru-RU', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
        });
        return getRateUtil(item?.type, fixedAmount, currentCurrency, item.amount);
    }

    isShowTrashBtn(item: IRatesHistory): boolean {
        const timeExpired = new Date() > new Date(item.action_date);
        return !timeExpired && !item.is_done;
    }

    async removeItem(id: number): Promise<void> {
        try {
            await deletePersonalRateTask(id);
            new NotificationMessage('Запланированное изменение удалено', 'success');
            await this.$emit('update');
        } catch (err) {
            showServerError(err, 'Ошибка удаления');
        }
    }

    get getSection(): string[] {
        const result: string[] = [];
        this.headers.forEach((header) => {
            if (!!header.section) {
                if (!result.includes(header.section)) {
                    result.push(header.section);
                }
            } else {
                result.push('');
            }
        });
        return result;
    }
}
