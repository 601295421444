import Debtors from "@/views/revenue/Debtors.vue";
import { USER_ROLES } from "@/mappings/user-roles";
import Invoices from "@/views/revenue/invoices/Invoices.vue";
import Invoice from "@/views/revenue/invoices/Single.vue";
import InvoiceCreation from "@/views/revenue/invoices/InvoiceCreation.vue";
import OtherServices from "@/views/revenue/otherServices/OtherServicesList.vue";
import StatTasks from "@/views/revenue/statTasks/StatTasks.vue";
import StatTask from "@/views/revenue/statTasks/Single.vue";
import ConversionsReport from "@/views/revenue/statTasks/reports/ConversionsReport.vue";
import StatTaskCreation from "@/views/revenue/statTasks/StatTaskCreation.vue";
import LegalEntities from "@/views/revenue/legalEntity/List.vue";
import LegalEntity from "@/views/revenue/legalEntity/Single.vue";
import { Route } from "vue-router";
import AdvertisersList from "@/views/revenue/advertisers/AdvertisersList.vue";
import AdvertiserSingleWrap from "@/views/revenue/advertisers/AdvertiserSingleWrap.vue";
import CreateAdvertiser from "@/views/revenue/advertisers/CreateAdvertiser.vue";

export default [
    {
        path: 'debtors',
        name: 'debtors',
        component: Debtors,
        meta: {
            title: 'Должники',
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN, USER_ROLES.ACCOUNT_MANAGER, USER_ROLES.FINANCIER, USER_ROLES.SUPERVISOR,
            ],
        },
        props: true,
    },
    {
        path: 'advertisers',
        name: 'advertisers',
        component: AdvertisersList,
        meta: {
            title: 'Рекламодатели',
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN, USER_ROLES.ACCOUNT_MANAGER, USER_ROLES.FINANCIER, USER_ROLES.SUPERVISOR, USER_ROLES.API_OPERATOR,
            ],
        },
        props: (route: Route): any => ({ ...route.query }),
    },
    {
        path: 'advertiser',
        redirect: 'advertisers',
    },
    {
        path: 'advertiser/:id',
        name: 'advertiser',
        component: AdvertiserSingleWrap,
        props: true,
        meta: {
            title: 'Просмотр рекламодателя',
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN, USER_ROLES.ACCOUNT_MANAGER, USER_ROLES.FINANCIER, USER_ROLES.SUPERVISOR, USER_ROLES.API_OPERATOR,
            ],
        },
    },
    {
        path: 'create-advertiser',
        name: 'create-advertiser',
        component: CreateAdvertiser,
        props: true,
        meta: {
            title: 'Создание рекламодателя',
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN, USER_ROLES.ACCOUNT_MANAGER, USER_ROLES.FINANCIER, USER_ROLES.SUPERVISOR, USER_ROLES.API_OPERATOR,
            ],
        },
    },
    {
        path: 'invoices',
        name: 'invoices',
        component: Invoices,
        meta: {
            title: 'Счета рекламодателей',
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN, USER_ROLES.ACCOUNT_MANAGER, USER_ROLES.FINANCIER, USER_ROLES.SUPERVISOR, USER_ROLES.STAT_MANAGER,
            ],
        },
        props: true,
    },
    {
        path: 'invoice',
        redirect: 'invoices',
    },
    {
        path: 'invoice/:id',
        name: 'invoice',
        component: Invoice,
        meta: {
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN, USER_ROLES.ACCOUNT_MANAGER, USER_ROLES.FINANCIER, USER_ROLES.SUPERVISOR, USER_ROLES.STAT_MANAGER,
            ],
        },
        props: true,
    },
    {
        path: 'invoice/creation/',
        name: 'invoice-creation',
        component: InvoiceCreation,
        meta: {
            title: 'Создание счёта',
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN, USER_ROLES.ACCOUNT_MANAGER, USER_ROLES.FINANCIER, USER_ROLES.SUPERVISOR, USER_ROLES.STAT_MANAGER,
            ],
        },
        props: true,
    },
    {
        path: 'invoice-creation/',
        name: 'invoice-creation-from-navigation',
        component: InvoiceCreation,
        meta: {
            title: 'Создание счёта',
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN, USER_ROLES.ACCOUNT_MANAGER, USER_ROLES.FINANCIER, USER_ROLES.SUPERVISOR, USER_ROLES.STAT_MANAGER,
            ],
        },
        props: true,
    },
    {
        path: 'other-services',
        name: 'other-services',
        component: OtherServices,
        meta: {
            title: 'Прочие услуги',
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN, USER_ROLES.FINANCIER, USER_ROLES.SUPERVISOR,
            ],
        },
        props: true,
    },
    {
        path: 'stat-tasks',
        name: 'stat-tasks',
        component: StatTasks,
        meta: {
            title: 'Загрузка статистики',
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN, USER_ROLES.ACCOUNT_MANAGER, USER_ROLES.FINANCIER, USER_ROLES.SUPERVISOR, USER_ROLES.STAT_MANAGER,
            ],
        },
    },
    {
        path: 'stat-task',
        redirect: 'stat-tasks',
    },
    {
        path: 'stat-task/:id',
        name: 'stat-task',
        component: StatTask,
        props: true,
        meta: {
            title: 'Просмотр загрузки',
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN, USER_ROLES.ACCOUNT_MANAGER, USER_ROLES.FINANCIER, USER_ROLES.SUPERVISOR, USER_ROLES.STAT_MANAGER,
            ],
        },
    },
    {
        path: 'stat-task/:id/conversions-change-report/',
        name: 'conversions-change-report',
        component: ConversionsReport,
        props: true,
        meta: {
            title: 'Отчёт по изменениям цен и статусов',
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN, USER_ROLES.ACCOUNT_MANAGER, USER_ROLES.FINANCIER, USER_ROLES.SUPERVISOR, USER_ROLES.STAT_MANAGER,
            ],
        },
    },
    {
        path: 'stat-task-creation',
        name: 'stat-task-creation',
        component: StatTaskCreation,
        meta: {
            title: 'Загрузка статистики',
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN, USER_ROLES.ACCOUNT_MANAGER, USER_ROLES.FINANCIER, USER_ROLES.SUPERVISOR, USER_ROLES.STAT_MANAGER,
            ],
        },
    },
    {
        path: 'legal-entities',
        name: 'legal-entities',
        component: LegalEntities,
        meta: {
            title: 'Наши юр.лица',
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN, USER_ROLES.ACCOUNT_MANAGER, USER_ROLES.FINANCIER, USER_ROLES.SUPERVISOR,
            ],
        },
    },
    {
        path: 'legal-entity',
        redirect: 'legal-entities',
    },
    {
        path: 'legal-entity/:id',
        name: 'legal-entity',
        component: LegalEntity,
        props: true,
        meta: {
            title: 'Просмотр юр.лица',
            requiresAuth: true,
            roles: [USER_ROLES.ADMIN, USER_ROLES.ACCOUNT_MANAGER, USER_ROLES.FINANCIER, USER_ROLES.SUPERVISOR,
            ],
        },
    },
];
