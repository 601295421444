
import { Component, Prop, Vue } from 'vue-property-decorator';
import TableWithFrontPaginationDesign from "@/components/base/table/TableWithFrontPaginationDesign.vue";
import { TableHeader, ITableStatus, IBaseAutocomplete } from "@/types";
import { OfferSingle } from "@/services/offers/OfferSingle";
import { IOfferErid } from "@/api/types/offers";
import BaseCard from "@/components/base/BaseCard.vue";
import SvgError from "@/assets/icons/false-icon.svg";
import { translateBoolean } from "@/utils/translate";
import ImageInput from "@/components/base/form/ImageInput.vue";
import Status from '@/components/base/Status.vue';
import DateTimePickerNew from "@/components/base/pickers/DateTimePickerNew.vue";
import EditBtn from '@/components/base/buttons/EditBtn.vue';
import SvgPlus from "@/assets/icons/plus.svg";
import EditIconBtn from '@/components/base/buttons/EditIconBtn.vue';
import BaseAutocomplete from '@/components/base/design/BaseAutocomplete.vue';
import { searchContracts, addingContract, getOfferErid } from '@/api/leadgid-ord';
import { getCurrentDate } from '@/utils/formatDates';
import router from '@/router';
import { IContracts } from '@/api/types/leadgid-ord';
import { showNotification, showServerError } from '@/utils';

@Component({
    methods: { translateBoolean },
    components: {
        ImageInput,
        SvgError,
        BaseCard,
        TableWithFrontPaginationDesign,
        Status,
        BaseAutocomplete,
        DateTimePickerNew,
        EditBtn,
        SvgPlus,
        EditIconBtn,
    },
})

export default class OfferCardErid extends Vue {
    @Prop({ required: true }) offer!: OfferSingle;

    isLoadingEditBtn: boolean = false;
    isAddContract: boolean = false;
    date: string = '';
    contractObj: IContracts | null = null;
    contractsData: IBaseAutocomplete = {
        errorText: 'Договор не найден',
        itemValue: 'id',
        template: (item: any): string => item.name + ' (' + item.company + ')',
        placeholder: 'Выберите договор',
        clearable: true,
        apiMethod: searchContracts,
        externalApiParams: (value: string) => ({
            search: value,
            offer_id: router.currentRoute.params.id,
        }),
    };
    timeData = [
        {
            slot: 'item.created_at',
            key: 'created_at',
            isLine: false,
        },
        {
            slot: 'item.updated_at',
            key: 'updated_at',
            isLine: false,
        },
    ];
    uniqueData = [
        {
            slot: 'item.is_current',
            template: (item): string => item.is_current !== undefined ?
                translateBoolean(item.is_current) : '',
        },
        {
            slot: 'item.url',
        },
        {
            slot: 'item.erid',
        },
    ];
    headers: TableHeader[] = [
        { text: 'Осн.', sortable: false, value: 'is_current', simpleTooltip: 'Актуальный' },
        { text: 'Статус', sortable: false, value: 'status', simpleTooltip: '' },
        { text: 'Erid', sortable: false, value: 'erid', align: 'left' },
        { text: 'Креатив', sortable: false, value: 'url', align: 'center' },
        { text: 'Договор', sortable: false, value: 'contract.number' },
        { text: 'Наша орг-ция', sortable: false, value: 'contract.company' },
        { text: 'Контрагент', sortable: false, value: 'contract.contractor', simpleTooltip: '' },
        { text: 'Создано', sortable: false, value: 'created_at', headerAlign: 'right' },
        { text: 'Обновлено', sortable: false, value: 'updated_at', headerAlign: 'right' },
    ];

    async handleAddedContract(): Promise<void> {
        try {
            if (this.contractObj !== null) {
                this.isLoadingEditBtn = true;
                const currentOfferId = Number(router.currentRoute.params.id);

                await addingContract(
                    currentOfferId,
                    {
                        contract_id: this.contractObj.id,
                        date: this.date,
                    },
                );
                await getOfferErid(currentOfferId);

                showNotification('Успешно! Данные сохранены');
                this.isAddContract = false;
                this.contractObj = null;
            }
        } catch (e) {
            showServerError(e, 'Не удалось сохранить');
        } finally {
            this.isLoadingEditBtn = false;
        }
    }

    get isDisabledEditBtn(): boolean {
        return this.contractObj === null || this.contractObj.errors.length !== 0 || this.isLoadingEditBtn;
    }

    get getOptions(): any[] {
        return [
            {
                id: 'ready_for_erir',
                name: 'Подлежит маркировке',
                value: this.erid.ready_for_erir,
                isBoolean: true,
            },
            {
                id: 'default_erid',
                name: 'Erid по умолчанию',
                value: this.erid.default_erid,
                class: '--chip v-chip',
            },
            {
                id: 'contract',
                name: 'Договор',
                value: this.erid.contract?.number,
            },
        ];
    }

    get statuses(): ITableStatus[] {
        return [
            {
                slot: 'item.status',
                key: 'erid',
                translate: (value: string) => (
                    value === undefined ?
                        { text: 'В работе', color: 'orange' } : { text: 'Готов', color: 'green' }
                ),
                tooltip: (item) => {
                    return {
                        disabled: item.erid !== undefined,
                        text: 'Маркировка офферов осуществляется каждые 30 минут. Ожидайте создания erid',
                    };
                },
            },
        ];
    }

    get erid(): IOfferErid {
        return this.offer.erid;
    }

    get isDefaultCreatives(): number {
        return this.erid.default_creatives?.length || 0;
    }

    created(): void {
        if (this.offer.erid.updating_contract !== null) {
            this.offer.erid.default_creatives.push({
                contract: this.offer.erid.updating_contract,
            });
        }

        this.date = getCurrentDate('yyyy-MM-dd');
    }
}
