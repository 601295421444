
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import { eventBus } from "@/eventbus";
import Status from "@/components/base/Status.vue";
import BaseSelect from "@/components/base/design/BaseSelect.vue";
import { translateBoolean, translateTwoFactor } from "@/utils/translate";
import { IUserV3 } from "@/api/types/user";
import { patchUserByTypeV3 } from "@/api/user";
import { showServerError } from "@/utils";
import { namespace } from 'vuex-class';
import { is } from "date-fns/locale";
import SvgPlus from "@/assets/icons/blue-plus.svg";
import BaseCard from "@/components/base/BaseCard.vue";
import SvgTrash from "@/assets/icons/trash.svg";
import { ACCOUNT_TYPE } from "@/mappings/account-type";

const auth = namespace('authModule');

@Component({
    computed: {
        is() {
            return is;
        },
    },
    methods: { translateTwoFactor, translateBoolean },
    components: {
        BaseCard,
        SvgPlus,
        BaseSelect,
        Status,
        SvgTrash,
    },
})
export default class UserCardMessengers extends Vue {
    @Prop({ required: true }) user!: IUserV3;
    @PropSync('isLoading') isLoadingSync!: boolean;

    @auth.Getter('GET_USER') currentUser;
    isEdit = false;
    userData: any = { roles: [''] };

    created(): void {
        this.setDefaults();
    }

    get getUserRolesFromType(): string[] | any[] {
        if (this.user.account_type) {
            const defaultRole = this.user.available![this.user.account_type].DEFAULT;
            const additionalRoles = this.user.available![this.user.account_type].ADDITIONAL;
            const filteredAdditionalRoles = additionalRoles.filter(role => role !== defaultRole);
            return filteredAdditionalRoles.sort().map(i => {
                return { id: i, text: i };
            });
        }
        return [];
    }

    updateUserRoles(index: number, data: any): void {
        this.$set(this.user.roles!, `[${index}]`, data);
    }

    editInfo(edit: boolean): void {
        if (edit) {
            this.setDefaults();
        } else {
            this.updateAllUserData();
        }
        this.isEdit = edit;
    }

    cancelEdit(): void {
        this.isEdit = false;
    }

    setDefaults(): void {
        const availableRoles = this.getUserRolesFromType.map((role: any) => role.text);
        // фильтруем this.user.roles, оставляя только те роли, которые есть в availableRoles
        this.userData.roles = this.user.roles!.filter(role => availableRoles.includes(role));
    }

    async updateUser(): Promise<void> {
        const id = this.user.account_type === ACCOUNT_TYPE.ADVERTISER ? this.user.account_id : this.user.id;
        try {
            const roles = this.userData.roles.filter((item) => item !== '');
            await patchUserByTypeV3(id as number, this.user.account_type!,
                {
                    roles,
                });
        } catch (err) {
            showServerError(err, 'Ошибка сохранения');
        }
    }

    removeRole(index: number): void {
        if (index >= 0 && index < this.userData.roles.length) {
            this.userData.roles.splice(index, 1);
        }
    }

    async updateAllUserData(): Promise<void> {
        this.isLoadingSync = true;
        await this.updateUser();
        eventBus.$emit("update-user");
        this.isLoadingSync = false;
    }
}
