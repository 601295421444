import { IFilter } from "@/types";
import { getEmployeesOffersV2 } from "@/api/offers";
import { getAffiliateSearch } from "@/api/user";
import { getAdvertisersSearch } from "@/api/revenue";
import { addDays, subDays } from "date-fns";
import { translateConversionStatus } from "@/utils/translate";
import { CreatedFilters } from "@/services/filters/filters";
import { filterClass } from '@/services/filters/filterClass';

export class ConversionFilters extends CreatedFilters {
    constructor() {
        super();
    }

    private getAllowedDates(item: { start: string, end: string } | null, drag: { start: string, end: string }): any {
        const isSelected = item?.start && item?.end;
        const isDragging = drag?.start && drag?.end;

        if (isDragging && !isSelected) {
            const max = addDays(new Date(drag.start), 149);
            const min = subDays(new Date(drag.end), 149);

            return {
                start: min,
                end: max > new Date()
                    ? new Date()
                    : max,
            };
        }

        return { start: null, end: new Date() };
    }

    getFilters(): IFilter[] {
        return [
            new filterClass.Datepicker({
                col: 'Дата',
                id: 'date',
                label: 'Период',
                pickerType: 'date',
                range: true,
                allowedDates: this.getAllowedDates,
                excludingItems: ['За текущий год'],
                clearable: true,
            }),
            new filterClass.Autocomplete({
                col: 1,
                id: 'offer_id',
                apiMethod: getEmployeesOffersV2,
                key: 'search',
                label: 'Оффер',
                itemValue: 'legacy_id',
                itemText: 'name',
                multiple: true,
                multiPaste: true,
                minSearchLength: 2,
            }),
            new filterClass.Multiselect({
                col: 1,
                id: 'goal_id',
                itemValue: 'legacy_id',
                itemText: (item: any): string => `${item.legacy_id} ${item.name}`,
                label: 'Цель',
                noDataText: 'Выберите оффер',
                clearable: true,
                disabled: true,
                type: 'number',
                template: (item: any): string => `${item.legacy_id} ${item.name}`,
                tooltip: 'Сначала отфильтруйте отчёт по офферу',
            }),
            new filterClass.Input({
                col: 2,
                id: 'adv_sub',
                label: 'Sub ID рекламодателя',
                isSubmitEmptySelect: false,
                disabled: true,
                clearable: false,
                tooltip: 'Сначала отфильтруйте отчёт по офферу',
            }),
            new filterClass.ComboboxArray({
                col: 2,
                id: 'affiliate_invoice_id',
                label: 'ID счета',
                supValue: '',
            }),
            new filterClass.Autocomplete({
                col: 1,
                id: 'affiliate_id',
                label: 'Партнер',
                apiMethod: getAffiliateSearch,
                itemValue: 'id',
                itemText: 'name',
                template: (i) => `${i.id} ${i.first_name} ${i.last_name} ${i.email}`,
                multiple: true,
                multiPaste: true,
            }),
            new filterClass.Input({
                col: 2,
                id: 'conversion_id',
                label: 'Conversion ID',
                isSubmitEmptySelect: false,
                clearable: false,
            }),
            new filterClass.Input({
                col: 2,
                id: 'click_id',
                label: 'Transaction ID',
                isSubmitEmptySelect: false,
                clearable: false,
                rules: [(v: any): any => (v || '').length === 30 || 'Transaction ID должен быть 30 символов'],
            }),
            new filterClass.Multiselect({
                col: 1,
                id: 'status',
                label: 'Статус',
                items: [{ id: 'approved' }, { id: 'rejected' }, { id: 'pending' }],
                select: [],
                type: 'string',
                defaultValue: ['approved'],
                itemValue: 'id',
                template: ({ id }) => this.translateStatus(id, translateConversionStatus),
                itemText: ({ id }) => this.translateStatus(id, translateConversionStatus),
            }),
            new filterClass.Input({
                col: 3,
                id: 'tracker_click_id',
                label: 'ID клика',
                isSubmitEmptySelect: false,
                clearable: false,
                rules: [(v: any): any => /^[0-9a-f]{8}-(?:[0-9a-f]{4}-){3}[0-9a-f]{12}$/.test(v) || 'ID клика имеет некорректный формат'],
            }),
            new filterClass.Input({
                col: 3,
                id: 'client_id',
                label: 'ID клиента',
                isSubmitEmptySelect: false,
                clearable: false,
            }),
            new filterClass.Autocomplete({
                col: 3,
                id: 'advertiser_id',
                label: 'Рекламодатель',
                multiple: true,
                apiMethod: getAdvertisersSearch,
                itemValue: 'id',
                itemText: 'name',
            }),
        ];
    }
}
export const conversionHeaders = [
    { text: 'ID Conversion (Lead ID)', sortable: false, value: 'conversion_id', width: '100px', align: 'left' },
    { text: 'ID Transaction', sortable: false, value: 'click_id', align: 'left' },
    { text: 'ID клиента', sortable: false, value: 'client_id', width: '190' },
    { text: 'ID счёта', sortable: false, value: 'affiliate_invoice_id', align: 'left' },
    { text: 'ID клика', sortable: false, value: 'tracker_click_id', align: 'left' },
    { text: 'Партнёр', sortable: false, value: 'affiliate_name' },
    { text: 'ID партнёра', sortable: false, value: 'affiliate_id' },
    { text: 'Менеджер', sortable: false, value: 'affiliate_manager_name' },
    { text: 'ID менеджера', sortable: false, value: 'affiliate_manager_id' },
    { text: 'Рекламодатель', sortable: false, value: 'advertiser_name' },
    { text: 'ID рекламодателя', sortable: false, value: 'advertiser_id' },
    { text: 'Менеджер рекламодателя', sortable: false, value: 'advertiser_manager_name' },
    { text: 'ID менеджера рекламодателя', sortable: false, value: 'advertiser_manager_id' },
    { text: 'ID мастера', sortable: false, value: 'master_account_id' },
    { text: 'Мастер', sortable: false, value: 'master_account_name' },
    { text: 'Буфер', sortable: false, value: 'buffer_account_used' },
    { text: 'ID Буфера', sortable: false, value: 'buffer_account_id' },
    { text: 'Имя буфера', sortable: false, value: 'buffer_account_name' },
    { text: 'Оффер', sortable: false, value: 'offer_name', align: 'left' },
    { text: 'ID оффера', sortable: false, value: 'offer_id', align: 'left' },
    { text: 'Цель', sortable: false, value: 'goal_name' },
    { text: 'ID цели', sortable: false, value: 'goal_id', align: 'left' },
    { text: 'Валюта', sortable: false, value: 'currency' },
    { text: 'Доход', sortable: false, align: 'right', value: 'revenue' },
    { text: 'Выплата', sortable: false, align: 'right', value: 'payout' },
    { text: 'Прибыль', sortable: false, value: 'profit', align: 'right' },
    { text: 'Период счёта', sortable: false, value: 'affiliate_invoice_period', align: 'left' },
    { text: 'Дата клика', sortable: false, value: 'click_date', width: '170px', align: 'left' },
    { text: 'Дата конверсии', sortable: false, value: 'conversion_date', width: '170px', align: 'left' },
    { text: 'Дата апрува', sortable: false, value: 'approval_date', width: '170px', align: 'left' },
    { text: 'Дата создания', sortable: false, value: 'creation_date', width: '170px', align: 'left' },
    { text: 'Дата обновления', sortable: false, value: 'update_date', width: '170px', align: 'left' },
    { text: 'Статус', sortable: false, align: 'center', value: 'status' },
    { text: 'IP сессии', sortable: false, value: 'session_ip' },
    { text: 'Sub ID рекламодателя', sortable: false, value: 'adv_sub' },
    { text: 'Sub ID1', sortable: false, value: 'subid1', align: 'left' },
    { text: 'Sub ID2', sortable: false, value: 'subid2', align: 'left' },
    { text: 'Sub ID3', sortable: false, value: 'subid3', align: 'left' },
    { text: 'Sub ID4', sortable: false, value: 'subid4', align: 'left' },
    { text: 'Sub ID5', sortable: false, value: 'subid5', align: 'left' },
    { text: 'Sub ID6', sortable: false, value: 'subid6', align: 'left' },
    { text: 'Sub ID7', sortable: false, value: 'subid7', align: 'left' },
    { text: 'Sub ID8', sortable: false, value: 'subid8', align: 'left' },
    { text: 'Sub ID9', sortable: false, value: 'subid9', align: 'left' },
    { text: 'Sub ID10', sortable: false, value: 'subid10', align: 'left' },
    { text: 'Sub ID11', sortable: false, value: 'subid11', align: 'left' },
    { text: 'Sub ID12', sortable: false, value: 'subid12', align: 'left' },
    { text: 'Sub ID13', sortable: false, value: 'subid13', align: 'left' },
    { text: 'Sub ID14', sortable: false, value: 'subid14', align: 'left' },
    { text: 'Sub ID15', sortable: false, value: 'subid15', align: 'left' },
    { text: 'Sub ID16', sortable: false, value: 'subid16', align: 'left' },
    { text: 'Sub ID17', sortable: false, value: 'subid17', align: 'left' },
    { text: 'Sub ID18', sortable: false, value: 'subid18', align: 'left' },
    { text: 'Sub ID19', sortable: false, value: 'subid19', align: 'left' },
    { text: 'Sub ID20', sortable: false, value: 'subid20', align: 'left' },
    { text: 'Sub ID21', sortable: false, value: 'subid21', align: 'left' },
    { text: 'Sub ID22', sortable: false, value: 'subid22', align: 'left' },
    { text: 'Sub ID23', sortable: false, value: 'subid23', align: 'left' },
    { text: 'Sub ID24', sortable: false, value: 'subid24', align: 'left' },
    { text: 'Sub ID25', sortable: false, value: 'subid25', align: 'left' },
];
export const conversionRoutes = [
    {
        routeParam: 'id',
        extraRouteParamName: 'offer_id',
        routerName: 'offer',
        slot: 'item.offer_name',
        // костыль, если offer_name пустой, тогда приходит пустой массив с бека, иначе приходит строка
        template: (item: any): string => Array.isArray(item.offer_name) ? item.offer_name[0] : item.offer_name,
    },
    {
        routerName: 'offer',
        routeParam: 'id',
        extraRouteParamName: 'offer_id',
        slot: 'item.offer_id',
        text: 'offer_id',
    },
    {
        routeParam: 'id',
        extraRouteParamName: 'offer_id',
        routerName: 'offer',
        slot: 'item.goal_name',
        // костыль, если goal_name пустой, тогда приходит пустой массив с бека, иначе приходит строка
        template: (item: any): string => Array.isArray(item.goal_name) ? item.goal_name[0] : item.goal_name,
    },
    {
        routeParam: 'id',
        extraRouteParamName: 'advertiser_id',
        routerName: 'advertiser',
        slot: 'item.advertiser_name',
        text: 'advertiser_name',
    },
    {
        routeParam: 'id',
        extraRouteParamName: 'advertiser_id',
        routerName: 'advertiser',
        slot: 'item.advertiser_id',
        text: 'advertiser_id',
    },
    {
        routeParam: 'id',
        extraRouteParamName: 'affiliate_invoice_id',
        routerName: 'affiliate-invoice',
        slot: 'item.affiliate_invoice_id',
        text: 'affiliate_invoice_id',
    },
    {
        routeParam: 'id',
        extraRouteParamName: 'affiliate_manager_id',
        routerName: 'user-detail-page',
        slot: 'item.affiliate_manager_id',
        text: 'affiliate_manager_id',
        tooltip: (item: any): string => item.affiliate_manager_id,
    },
    {
        routeParam: 'id',
        extraRouteParamName: 'affiliate_manager_id',
        routerName: 'user-detail-page',
        slot: 'item.affiliate_manager_name',
        text: 'affiliate_manager_name',
        tooltip: (item: any): string => item.affiliate_manager_name,
    },
    {
        routeParam: 'id',
        extraRouteParamName: 'offer_id',
        routerName: 'offer',
        slot: 'item.goal_id',
        text: 'goal_id',
    },
    {
        routeParam: 'id',
        extraRouteParamName: 'affiliate_id',
        routerName: 'affiliate',
        slot: 'item.affiliate_id',
        text: 'affiliate_id',
    },
    {
        routeParam: 'id',
        extraRouteParamName: 'affiliate_id',
        routerName: 'affiliate',
        slot: 'item.affiliate_name',
        text: 'affiliate_name',
    },
];

export const columnsConversionsReport: Array<{ base: string, letters: string }> = [
    { base: 'ID Conversion (Lead ID)', letters: 'conversion_id' },
    { base: 'ID Transaction', letters: 'click_id' },
    { base: 'ID партнера', letters: 'affiliate_id' },
    { base: 'ID оффера', letters: 'offer_id' },
    { base: 'Оффер', letters: 'offer_name' },
    { base: 'ID цели', letters: 'goal_id' },
    { base: 'Цель', letters: 'goal_name' },
    { base: 'Период счёта', letters: 'affiliate_invoice_period' },
    { base: 'Дата апрува', letters: 'approval_date' },
    { base: 'Дата клика', letters: 'click_date' },
    { base: 'Дата конверсии', letters: 'conversion_date' },
    { base: 'Дата создания', letters: 'creation_date' },
    { base: 'Дата конверсии', letters: 'conversion_date' },
    { base: 'Дата обновления', letters: 'update_date' },
    { base: 'ID Буфера', letters: 'buffer_account_id' },
    { base: 'Буфер', letters: 'buffer_account_used' },
    { base: 'Имя Буфера', letters: 'buffer_account_name' },
    { base: 'ID мастера', letters: 'master_account_id' },
    { base: 'Мастер', letters: 'master_account_name' },
    { base: 'IP сессии', letters: 'session_ip' },
    { base: 'ID клиента', letters: 'client_id' },
    { base: 'Sub ID рекламодателя', letters: 'adv_sub' },
    { base: 'Sub ID1', letters: 'subid1' },
    { base: 'Sub ID2', letters: 'subid2' },
    { base: 'Sub ID3', letters: 'subid3' },
    { base: 'Sub ID4', letters: 'subid4' },
    { base: 'Sub ID5', letters: 'subid5' },
    { base: 'Sub ID6', letters: 'subid6' },
    { base: 'Sub ID7', letters: 'subid7' },
    { base: 'Sub ID8', letters: 'subid8' },
    { base: 'Sub ID9', letters: 'subid9' },
    { base: 'Sub ID10', letters: 'subid10' },
    { base: 'Sub ID11', letters: 'subid11' },
    { base: 'Sub ID12', letters: 'subid12' },
    { base: 'Sub ID13', letters: 'subid13' },
    { base: 'Sub ID14', letters: 'subid14' },
    { base: 'Sub ID15', letters: 'subid15' },
    { base: 'Sub ID16', letters: 'subid16' },
    { base: 'Sub ID17', letters: 'subid17' },
    { base: 'Sub ID18', letters: 'subid18' },
    { base: 'Sub ID19', letters: 'subid19' },
    { base: 'Sub ID20', letters: 'subid20' },
    { base: 'Sub ID21', letters: 'subid21' },
    { base: 'Sub ID22', letters: 'subid22' },
    { base: 'Sub ID23', letters: 'subid23' },
    { base: 'Sub ID24', letters: 'subid24' },
    { base: 'Sub ID25', letters: 'subid25' },
    { base: 'Статус', letters: 'status' },
    { base: 'Валюта', letters: 'currency' },
    { base: 'Выплата', letters: 'payout' },
    { base: 'Доход', letters: 'revenue' },
    { base: 'Прибыль', letters: 'profit' },
    { base: 'ID счёта', letters: 'invoice_id' },
];

export const conversionFilterCols = [
    {
        name: '',
        items: [
            { title: 'ID Conversion', value: 'conversion_id' },
            { title: 'ID Transaction', value: 'click_id' },
            { title: 'ID счёта', value: 'affiliate_invoice_id' },
            { title: 'ID клиента', value: 'client_id' },
            { title: 'ID клика', value: 'tracker_click_id' },
        ],
    },
    {
        name: '',
        items: [
            { title: 'Партнёр', value: 'affiliate_name' },
            { title: 'ID партнёра', value: 'affiliate_id' },
            { title: 'Мастер', value: 'master_account_name' },
            { title: 'ID мастера', value: 'master_account_id' },
            { title: 'Буфер', value: 'buffer_account_used' },
            { title: 'Имя буфера', value: 'buffer_account_name' },
            { title: 'ID Буфера', value: 'buffer_account_id' },
            { title: 'Менеджер', value: 'affiliate_manager_name' },
            { title: 'ID менеджера', value: 'affiliate_manager_id' },
        ],
    },
    {
        name: '',
        items: [
            { title: 'Рекламодатель', value: 'advertiser_name' },
            { title: 'ID рекламодателя', value: 'advertiser_id' },
            { title: 'Менеджер рекламодателя', value: 'advertiser_manager_name' },
            { title: 'ID менеджера рекламодателя', value: 'advertiser_manager_id' },
            { title: 'Оффер', value: 'offer_name' },
            { title: 'ID Оффера', value: 'offer_id' },
            { title: 'Цель', value: 'goal_name' },
            { title: 'ID цели', value: 'goal_id' },
        ],
    },
    {
        name: '',
        items: [
            { title: 'Валюта', value: 'currency' },
            { title: 'Доход', value: 'revenue' },
            { title: 'Выплата', value: 'payout' },
            { title: 'Прибыль', value: 'profit' },
            { title: 'Период счёта', value: 'affiliate_invoice_period' },
        ],
    },
    {
        name: '',
        items: [
            { title: 'Дата клика', value: 'click_date' },
            { title: 'Дата конверсии', value: 'conversion_date' },
            { title: 'Дата апрува', value: 'approval_date' },
            { title: 'Дата создания', value: 'creation_date' },
            { title: 'Дата обновления', value: 'update_date' },
            { title: 'Статус', value: 'status' },
            { title: 'IP сессии', value: 'session_ip' },
            { title: 'Sub ID рекламодателя', value: 'adv_sub' },
        ],
    },
    {
        value: false,
        name: 'Sub ID',
        items: [
            { title: 'Sub ID 1', value: 'subid1' },
            { title: 'Sub ID 2', value: 'subid2' },
            { title: 'Sub ID 3', value: 'subid3' },
            { title: 'Sub ID 4', value: 'subid4' },
            { title: 'Sub ID 5', value: 'subid5' },
            { title: 'Sub ID 6', value: 'subid6' },
            { title: 'Sub ID 7', value: 'subid7' },
            { title: 'Sub ID 8', value: 'subid8' },
            { title: 'Sub ID 9', value: 'subid9' },
            { title: 'Sub ID 10', value: 'subid10' },
            { title: 'Sub ID 11', value: 'subid11' },
            { title: 'Sub ID 12', value: 'subid12' },
            { title: 'Sub ID 13', value: 'subid13' },
            { title: 'Sub ID 14', value: 'subid14' },
            { title: 'Sub ID 15', value: 'subid15' },
            { title: 'Sub ID 16', value: 'subid16' },
            { title: 'Sub ID 17', value: 'subid17' },
            { title: 'Sub ID 18', value: 'subid18' },
            { title: 'Sub ID 19', value: 'subid19' },
            { title: 'Sub ID 20', value: 'subid20' },
            { title: 'Sub ID 21', value: 'subid21' },
            { title: 'Sub ID 22', value: 'subid22' },
            { title: 'Sub ID 23', value: 'subid23' },
            { title: 'Sub ID 24', value: 'subid24' },
            { title: 'Sub ID 25', value: 'subid25' },
        ],
    },
];
