import { CreatedFilters } from "@/services/filters/filters";
import { IFilter } from "@/types";
import { filterClass } from "@/services/filters/filterClass";
import { translateUserStatus, translateUserType } from "@/utils/translate";
import { ACCOUNT_TYPE } from "@/mappings/account-type";
import { USER_ROLES } from "@/mappings/user-roles";
import store from "@/store";

export class UsersFilters extends CreatedFilters {
    constructor() {
        super();
    }

    getFilters(): IFilter[] {
        return [
            new filterClass.ComboboxArray({
                id: 'account_ids',
                label: 'Account ID',
                select: [],
                type: 'number',
            }),
            new filterClass.ComboboxArray({
                id: 'ids',
                label: 'User ID',
                select: [],
                type: 'number',
            }),
            new filterClass.Input({
                id: 'first_name',
                label: 'Имя',
            }),
            new filterClass.Input({
                id: 'last_name',
                label: 'Фамилия',
            }),
            new filterClass.Input({
                id: 'email',
                label: 'E-mail',
            }),
            new filterClass.Select({
                id: 'account_type',
                label: 'Тип аккаунта',
                clearable: true,
                remove: true,
                itemValue: 'value',
                itemText: 'name',
                select: ACCOUNT_TYPE.EMPLOYEE,
                defaultValue: ACCOUNT_TYPE.EMPLOYEE,
                items: [ACCOUNT_TYPE.EMPLOYEE, ACCOUNT_TYPE.AFFILIATE, ACCOUNT_TYPE.SYSTEM, ACCOUNT_TYPE.ADVERTISER],
                translate: (i) => translateUserType(i).text,
            }),
            new filterClass.Select({
                id: 'statuses',
                label: 'Статус аккаунта',
                select: 'active',
                defaultValue: 'active',
                itemValue: 'value',
                itemText: 'name',
                items: ['active', 'deleted'],
                translate: (i) => translateUserStatus(i).text,
            }),
            new filterClass.Multiselect({
                id: 'roles',
                label: 'Роли',
                select: [],
                items: Object.values(USER_ROLES),
            }),
            new filterClass.Datepicker({
                id: 'created',
                label: 'Дата создания',
                pickerType: 'date',
                select: [],
                range: true,
            }),
            new filterClass.ComboboxArray({
                id: 'token_ids',
                label: 'ID токена',
                select: [],
                type: 'number',
            }),
        ];
    }

    setDefaultFilters(): any {
        const query = this.getQueryFilters();
        const activeFilters: object[] = [];
        const dateParam = {};
        this.filters.forEach((filter) => {
            if (filter.select.toString().length > 0) {
                activeFilters.push({
                    [filter.id]: filter.select,
                });
            }
            if (Object.keys(query).length > 0 && filter.select) {
                filter.select = '';
            }
        });
        const paramsObj = { ...Object.assign({}, ...activeFilters), ...dateParam };
        store.commit('SET_DEFAULT_FILTERS_PARAMS_OBJECT', paramsObj);
        if (Object.keys(query).length <= 0) {
            store.commit('SET_FILTERS_PARAMS_OBJECT', paramsObj);
        }
    }

}
