
import { Vue, Component, Prop, PropSync } from "vue-property-decorator";

@Component({})

export default class FormTextField extends Vue {
    @PropSync('product') productSync!: any;
    @Prop({ required: true }) isEdit!: boolean;
    @Prop({ required: true }) param!: any;

    // NOTE: Поля у которых будет происходить форматирование
    toFixedFields: string[] = ['psk_from', 'psk_to'];

    updatedNumber(e: string): void {
        if (this.param.type === 'number') {
            if (this.toFixedFields.includes(this.param.param) && Number(e) !== 0) {
                const splitValue = e.split('.');

                if (splitValue.length > 1) {
                    this.productSync.params[this.param.param] = `${splitValue[0]}.${splitValue[1].slice(0, 3)}`;
                }
            } else {
                this.productSync.params[this.param.param] = e;
            }
        }
    }
}
