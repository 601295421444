import { IFilter } from '@/types';
import { FilterRouteParser } from '@/services/filters/filterRouteParser';
import { ConstructorType } from '@/services/filters/classes/types';

export class BaseFilter implements IFilter {
    allowedDates?: any;
    apiMethod?: any;
    apiParseUrl?: boolean;
    array?: boolean;
    autocomplete?: boolean;
    callback?: (item: any) => any;
    changeSelect?: ({ type, items }: { type: any; items: any; }) => void;
    checkMultiPaste?: boolean;
    checkbox?: boolean;
    chipWidth?: number;
    class?: string;
    clearable?: boolean;
    col?: string | number;
    colName?: string | number;
    combobox?: boolean;
    compare?: boolean;
    context?: (item: any) => any;
    customParams?: any;
    customSlot?: boolean;
    dadata?: boolean;
    datepicker?: boolean;
    defaultValue?: any;
    disabled?: boolean;
    disabledDates?: any;
    dontUseValue?: string;
    errorText?: string;
    excludingItems?: string[];
    falseValue?: any;
    hasOptions?: boolean;
    id: string;
    isChip?: boolean;
    isColumnTable?: boolean;
    isComplexPicker?: boolean;
    isCountryCatalogue?: boolean;
    isCurrencyCatalogue?: boolean;
    isDefaultDate?: boolean;
    isErrorIcon?: boolean;
    isExcluded?: boolean;
    isNeedSetIdForItems?: boolean;
    isHidden?: boolean;
    isMarker?: (item: any) => boolean;
    isMonthPicker?: boolean;
    isNoChips?: boolean;
    isOnlyPlaceholder?: boolean;
    isShowCheckbox?: boolean;
    isShowFullName?: boolean;
    isShowUnlimited?: boolean;
    isStatus?: boolean;
    isSubmitEmptySelect?: boolean;
    isWeekPicker?: boolean;
    itemText?: string | ((item: any) => string);
    itemValue?: string;
    key?: string;
    label: string;
    minSearchLength?: number;
    multiPaste?: boolean;
    multiple?: boolean;
    multiselect?: boolean;
    noDataText?: string;
    operator?: string;
    pickerType?: 'date' | 'month';
    placeholder?: string;
    protected _items?: any[] = [];
    protected _select?: any;
    queryValue?: (item: any) => any;
    range?: boolean;
    remove?: boolean | Function;
    rules?: any;
    selectWithHeaders?: boolean;
    supValue?: string;
    tab?: number;
    template?: (item: any) => any;
    tooltip?: string;
    translate?: (item: any) => any;
    trueValue?: any;
    type?: 'number' | 'string' | string;
    isTimeAdder?: boolean;
    defaultTime?: string | undefined;

    constructor(config: ConstructorType<BaseFilter, any>) {
        Object.entries(config).forEach(([key, value]) => {
            this[key] = value;
        });
        this.id = config.id;
        this.label = config.label;
        this.select = config.select ?? '';
        this.items = config.items ?? [];
    }

    get select(): any {
        return this._select;
    }
    set select(value: any) {
        this._select = value;
        this.onSelectChange();
    }
    // Сделано отдельной функцией, так как нельзя переопределить геттеры и сеттеры в наследуемых классах
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSelectChange(): void {}

    get items(): any[] {
        return this._items ?? [];
    }
    set items(value: any[] | undefined) {
        this._items = value;
        this.onItemsChange();
    }
    // Сделано отдельной функцией, так как нельзя переопределить геттеры и сеттеры в наследуемых классах
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onItemsChange(): void {}

    clear(): void {
        this.select = this.defaultValue ?? (Array.isArray(this.select) ? [] : '');
        if (this.isExcluded !== undefined) this.isExcluded = false;
        if (this.isSubmitEmptySelect !== undefined) this.isSubmitEmptySelect = false;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    parseQueryAndGetChanges(query: any, currentQueryKey: any, parser: FilterRouteParser): Record<string, any> {
        if (Array.isArray(this.select)) {
            return this.parseSelect(query, currentQueryKey);
        }

        if (this.id.slice(0, 3) === 'sub') {
            this.select = query[currentQueryKey] ?? '';

            return { [this.id]: this.select };
        }

        this.select = this.castQueryValueToType(query[currentQueryKey]);

        return { [this.id]: this.select };
    }

    getValueForSubmit(filter: IFilter = this): Record<string, any> {
        if (filter.select.hasOwnProperty('length') && !filter.select.length) {
            return {};
        }
        return { [filter.id]: filter.select };
    }

    protected castQueryValueToType(rawValue: string | string[]): string | number | string[] | number[] {
        const value = Array.isArray(rawValue) ? rawValue : Array.isArray(this.select) ? [rawValue] : rawValue;
        if (this.type === 'number') {
            return Array.isArray(value) ? value.map(Number) : Number(value);
        }
        return value;
    }

    protected parseSelect(query: any, currentQueryKey: any): Record<string, any> {
        const value = this.castQueryValueToType(query[currentQueryKey]);
        if (this.apiParseUrl === undefined) {
            this.select = value;
        }
        return { [this.id]: value };
    }
}
