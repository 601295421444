
import BaseCard from '@/components/base/BaseCard.vue';
import { Component, Prop, Vue, PropSync } from 'vue-property-decorator';
import { IEmployeeAffiliateMethod } from '@/api/types/payout';
import { translatePaymentsRequisite } from '@/utils/translate';
import { searchWebmasters } from '@/api/org-cabinets-backend';
import Status from '@/components/base/Status.vue';
import BaseAutocomplete from '@/components/base/design/BaseAutocomplete.vue';
import BaseList from '@/components_v3/base/list/BaseList.vue';
import BaseListItem from '@/components_v3/base/list/BaseListItem.vue';

@Component({
    components: {
        BaseCard,
        Status,
        BaseAutocomplete,
        BaseList,
        BaseListItem,
    },
})

export default class PayoutMethodRequisites extends Vue {
    @Prop({ required: true }) employeeAffiliateMethod!: IEmployeeAffiliateMethod;
    @Prop({}) isShowEditFields!: boolean;
    @Prop({}) methodData!: IEmployeeAffiliateMethod;
    @PropSync('defaultSearch', { required: false }) search!: string;
    @PropSync('managerFieldsKeys', { required: false }) keys!: {};

    translatePaymentsRequisite = translatePaymentsRequisite;
    searchWebmasters = searchWebmasters;

    get isDisabledField(): boolean {
        return this.employeeAffiliateMethod.status === 'partner_rejected' ||
            this.employeeAffiliateMethod.status === 'deleted';
    }

    get isShowFields(): (name: string) => boolean {
        return (name: string): boolean => {
            return this.isShowEditFields &&
                !(this.employeeAffiliateMethod.method?.name.ru.includes('Договор (Россия и Казахстан)') &&
                    name === 'legal_name');
        };
    }

    changeFieldValue(key: string, value: string): void {
        this.keys[key] = value;
        this.$emit('change', this.keys);
    }
}
