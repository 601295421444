
import { Component, Vue } from 'vue-property-decorator';
import SvgCheckBtn from '@/assets/icons/check-btn.svg';
import SvgCancel from '@/assets/icons/cancel.svg';
import { IEmployeeAffiliateMethod } from '@/api/types/payout';
import {
    approveRequestAffiliateMethod,
    getOneEmployeeAffiliateMethod, makeDefaultAffiliateMethod,
    rejectRequestAffiliateMethod,
} from '@/api/payout';
import { showServerError } from '@/utils';
import { translatePayoutsRequestStatus } from '@/utils/translate';
import PageLayout from '@/components/layout/PageLayout.vue';
import PayoutMethodInfo from '@/components/affiliate/affiliateMethods/PayoutMethodInfo.vue';
import Requisites from '@/components/affiliate/affiliateMethods/Requisites.vue';
import Status from '@/components/base/Status.vue';
import { NotificationMessage } from '@/utils/notification';
import { getWebmastersSearchGuid } from '@/api/orgstats';

enum STATUS {
    NEW = 'new',
    APPROVED = 'approved',
    PARTNER_REJECTED = 'partner_rejected',
    REJECTED = 'manager_rejected',
    EDITED = 'partner_edited',
}

@Component({
    components: {
        PageLayout,
        Status,
        SvgCheckBtn,
        SvgCancel,
        PayoutMethodInfo,
        Requisites,
    },
})
export default class ChangeMethodRequest extends Vue {
    employeeAffiliateMethod = {} as IEmployeeAffiliateMethod;
    reason = '';

    translatePayoutsRequestStatus = translatePayoutsRequestStatus;
    managerFieldsKeys = {};
    defaultSearch: string = '';

    get title(): string {
        const id = this.employeeAffiliateMethod?.id || '';
        return `Способ выплаты ${id}`;
    }

    get employeeAffiliateMethodID(): string {
        return this.$route.params.id;
    }

    get isShowEditFields(): boolean {
        return this.employeeAffiliateMethod.method !== undefined &&
            this.employeeAffiliateMethod.method.manager_fields_required &&
                (this.employeeAffiliateMethod.status !== 'approved' &&
                    this.employeeAffiliateMethod.status !== 'deleted' &&
                        this.employeeAffiliateMethod.status !== 'partner_rejected');
    }

    get getMethodData(): IEmployeeAffiliateMethod {
        let data = {} as IEmployeeAffiliateMethod;

        if (!Array.isArray(this.employeeAffiliateMethod.partner_data) &&
            this.employeeAffiliateMethod.method?.partner_fields_required
        ) {
            data = { ...data, ...this.employeeAffiliateMethod.partner_data };
        }

        if (!Array.isArray(this.employeeAffiliateMethod.manager_data) &&
            this.employeeAffiliateMethod.method?.manager_fields_required &&
                (this.employeeAffiliateMethod.status === 'approved' ||
                    this.employeeAffiliateMethod.status === 'deleted' ||
                        this.employeeAffiliateMethod.status === 'partner_rejected')
        ) {
            data = { ...data, ...this.employeeAffiliateMethod.manager_data };
        }

        return data;
    }

    get isShowRejectBtn(): boolean {
        return this.employeeAffiliateMethod.status === STATUS.APPROVED
            || this.employeeAffiliateMethod.status === STATUS.NEW
            || this.employeeAffiliateMethod.status === STATUS.EDITED;
    }

    get isShowApproveBtn(): boolean {
        return  this.employeeAffiliateMethod.status === STATUS.NEW
            || this.employeeAffiliateMethod.status === STATUS.EDITED
            || this.employeeAffiliateMethod.status === STATUS.REJECTED;
    }

    get isNewRequest(): boolean {
        return this.employeeAffiliateMethod.status === STATUS.NEW
            || this.employeeAffiliateMethod.status === STATUS.EDITED ;
    }

    get isManagerFieldsKeys(): boolean {
        return !!this.employeeAffiliateMethod.method?.manager_fields_required;
    }

    async created(): Promise<void> {
        const ID: string = this.employeeAffiliateMethodID;
        try {
            this.employeeAffiliateMethod = await getOneEmployeeAffiliateMethod(ID);

            if (Array.isArray(this.employeeAffiliateMethod.manager_data) &&
                this.employeeAffiliateMethod.method?.manager_fields_keys !== undefined
            ) {
                this.managerFieldsKeys = this.employeeAffiliateMethod.method?.manager_fields_keys.reduce((acc, el) => (acc[el] = '', acc), {});
            } else {
                this.managerFieldsKeys = this.employeeAffiliateMethod.manager_data;

                if (this.employeeAffiliateMethod.manager_data?.legal_id) {
                    const resp: any = await getWebmastersSearchGuid(this.employeeAffiliateMethod.manager_data?.legal_id);
                    this.defaultSearch = resp[0].name;
                }
            }
        } catch (err) {
            showServerError(err, 'Информация о способе выплаты не найдена');
        }
    }

    showNotification(text: string, type: string): void {
        new NotificationMessage(text, type);
    }

    async approveMethod(managerData = this.employeeAffiliateMethod.manager_data): Promise<void> {
        try {
            const { data } = this.isManagerFieldsKeys
                ? await approveRequestAffiliateMethod(
                    this.employeeAffiliateMethod.id,
                    { data: { ...managerData.manager_data || managerData } },
                )
                : await approveRequestAffiliateMethod(this.employeeAffiliateMethod.id);
            this.showNotification('Способ выплаты подтверждён','success');
            this.changeMethod(data);
        } catch (err) {
            showServerError(err, 'Запрос на изменение способа выплат не подтвержден');
        }
    }

    async rejectMethod(): Promise<void> {
        try {
            const { data } = await rejectRequestAffiliateMethod(this.employeeAffiliateMethod.id, this.reason);
            this.showNotification('Способ выплаты отклонён','info');
            this.changeMethod(data);
        } catch (err) {
            showServerError(err, 'Запрос на изменение способа выплат не отклонен');
        }
    }
    async makeDefault(): Promise<void> {
        try {
            const { data } = await makeDefaultAffiliateMethod(this.employeeAffiliateMethod.id);
            this.showNotification('Способ выплаты назначен основным','info');
            this.changeMethod(data);
        } catch (err) {
            showServerError(err, 'Способ выплаты не назначен основным');
        }
    }

    changeMethod(data: IEmployeeAffiliateMethod): void {
        this.employeeAffiliateMethod.status = data.status;
        this.employeeAffiliateMethod.manager_id = data.manager_id;
        this.employeeAffiliateMethod.is_default = data.is_default;
        this.employeeAffiliateMethod.manager_data = data.manager_data;
    }

    changeManagerData(data: any): void {
        this.employeeAffiliateMethod.manager_data = {
            ...this.employeeAffiliateMethod.manager_data,
            ...data,
        };
    }
}
