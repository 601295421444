import { showNotification } from "@/utils/index";

export const useCopyText = (text: string, notifyText: string = 'Ссылка скопирована'): void => {
    if (window.isSecureContext && navigator.clipboard) {
        navigator.clipboard.writeText(text).then(() => {
            showNotification(notifyText);
        });
    } else {
        unsecuredCopyToClipboard(text, notifyText);
    }
};

const unsecuredCopyToClipboard = (text, notifyText) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
        document.execCommand('copy');
        showNotification(notifyText);
    } catch (err) {
        console.error('Unable to copy to clipboard', err);
    }
    document.body.removeChild(textArea);
};
