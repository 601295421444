import { IColumnFilter } from "@/types";
import { AffiliateColumns } from "@/services/TablePage/affiliates";

export class RegistrationColumns extends AffiliateColumns{
    constructor() {
        super();
    }

    getColumns(): IColumnFilter[] {
        return [
            {
                name: '',
                items: [
                    { title: 'ID', value: 'id' },
                    { title: 'User ID', value: 'user_id', default: false },
                    { title: 'Имя', value: 'full_name' },
                    { title: 'Никнейм', value: 'nickname', default: false },
                    { title: 'Мастер-аккаунт', value: 'master_id' },
                    { title: 'Имя мастер-аккаунта', value: 'master_account_name', default: false },
                    { title: 'Почта', value: 'email' },
                    { title: 'Статус почты', value: 'email_verified' },
                    { title: 'Статус телефона', value: 'phone_verified', default: false },
                    { title: 'Телефон', value: 'phone', default: true },
                    { title: 'Аккаунт-менеджер', value: 'manager.full_name', default: false },
                    { title: 'Вертикаль', value: 'verticals' },
                    { title: 'Страна', value: 'country', default: false },
                    { title: 'Город', value: 'city', default: false },
                    { title: 'Язык', value: 'language' },
                    { title: 'Статус', value: 'status' },
                    { title: 'Источник трафика', value: 'traffic_sources', default: false },
                    { title: 'Реферер', value: 'referer_url', default: false },
                ],
            },
            {
                name: '',
                items: [
                    { value: 'utms.utm_source', title: 'Utm source' },
                    { value: 'utms.utm_medium', title: 'Utm medium' },
                    { value: 'utms.utm_campaign', title: 'Utm campaign' },
                    { value: 'utms.utm_term', title: 'Utm term' },
                    { value: 'utms.gid', title: 'Gid', default: false },
                    { value: 'utms.aid', title: 'Aid', default: false },
                    { value: 'utms.gclid', title: 'Gclid', default: false },
                    { value: 'utms.creative', title: 'Creative', default: false },
                    { value: 'utms.placement', title: 'Placement', default: false },
                    { value: 'utms.rand', title: 'Rand', default: false },
                    { title: 'Зарегистрирован', value: 'signup_at' },
                    { title: 'Создан', value: 'created_at', default: false },
                    { title: 'Обновлён', value: 'updated_at', default: false },
                    { title: 'Последний логин', value: 'last_login', default: false },
                    { title: 'Теги', value: 'tags', default: false },
                ],
            },
        ];
    }
}
