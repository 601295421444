import { IPresetParams } from "@/api/types/stats";
import router from "@/router";
import { IFilter } from "@/types";
import store from "@/store";
import {
    getCurrentDate,
    getCurrentWeek,
    getCurrentYear,
    getFullCurrentMonth,
    getFullCurrentWeek,
    getFullPreviousMonth,
    getFullPreviousWeek,
    getPreviousDate,
} from '@/utils/formatDates';
import isEqual from "lodash-es/isEqual";

const groupByTemplate = [
    ['dm_lead_date_hour', 'hour'], ['dm_lead_date', 'date'],
    ['dm_lead_date_week', 'week'], ['dm_lead_date_month', 'month'],
    ['dm_lead_date_year', 'year'],
];

enum DATE_TEMPLATE {
    'Сегодня' = 'today',
    'Вчера' = 'yesterday',
    'Последние 7 дней' = 'days7',
    'За текущую неделю' = 'currentWeek',
    'За прошлую неделю' = 'lastWeek',
    'За текущий месяц' = 'currentMonth',
    'За прошлый месяц' = 'lastMonth',
    'За текущий год' = 'year',
    'Произвольный период' = 'custom',
}

const dateFormat = 'yyyy-MM-dd';
const dateTemplateValue = {
    today: getCurrentDate(dateFormat),
    yesterday: getPreviousDate(dateFormat),
    days7: getCurrentWeek(dateFormat),
    currentWeek: getFullCurrentWeek(dateFormat),
    lastWeek: getFullPreviousWeek(dateFormat),
    currentMonth: getFullCurrentMonth(dateFormat),
    lastMonth: getFullPreviousMonth(
        dateFormat,
        new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
    ),
    year: getCurrentYear(dateFormat, new Date(new Date().getFullYear(), 0, 1)),
};

const findTmp = (name: string = 'without_lead_date'): string[] | undefined => {
    return name !== 'without_lead_date' ? groupByTemplate.find(i => i.includes(name)) : [name];
};

export const preparePresetData = (title: string, filters: IFilter[]): IPresetParams => {
    const query: any = router.currentRoute.query;
    const date = filters.find(el => el.id === 'date');
    const data: IPresetParams = {
        name: '',
        query: {
            columns: [],
            filters: [],
            period: {
                type: '',
                value: [],
            },
            form_by: '',
            currency: '',
            sorting: {
                column: '',
                direction: '',
            },
        },
    };

    data.name = title;
    data.query.columns = query.columns;
    data.query.currency = query.convert_currency || '';
    data.query.form_by = query.date_click.replace('-', '_');

    if (query.group !== 'without_lead_date') {
        data.query.group_by = findTmp(query.group)?.[1];
    }

    data.query.sorting.column = query.sort_by;
    data.query.sorting.direction = query.sort_desc ? 'desc' : 'asc';
    data.query.period = { type: 'today', value: [] };

    if (date?.select !== undefined && date?.periodTemplate !== undefined) {
        data.query.period.type = DATE_TEMPLATE[date?.periodTemplate];
        data.query.period.value = DATE_TEMPLATE[date?.periodTemplate] === 'custom' ? date.select : [];
    }

    for (const i in query) {
        if (['columns', 'date_from', 'date_to', 'sort_by', 'sort_desc', 'offset', 'group', 'date_click', 'convert_currency'].includes(i)) continue;

        if (query[i].length !== 0) {
            data.query.filters.push({
                column: i + '.' + typeof query[i],
                values: Array.isArray(query[i]) ? query[i] : [query[i]],
            });
        }
    }

    return data;
};

export const prepareQueryPresetData = (presets: IPresetParams[]): IPresetParams[] => {
    return presets.map((el: IPresetParams) => {
        const query: any = {};

        query.columns = el.query.columns;
        query.date_click = el.query.form_by.replace('_', '-');
        query.group = findTmp(el.query.group_by)?.[0];
        query.sort_by = el.query.sorting.column;
        query.sort_desc = el.query.sorting.direction === 'desc';

        if (el.query.period.type === 'custom') {
            query.date_to = el.query.period.value[1];
            query.date_from = el.query.period.value[0];
        } else {
            const date = dateTemplateValue[el.query.period.type];
            query.date_to = Array.isArray(date) ? date[1] : date;
            query.date_from = Array.isArray(date) ? date[0] : date;
        }

        if (el.query.currency) {
            query.convert_currency = el.query.currency;
        }

        el.query.filters?.forEach(e => {
            const splitVal = e.column.split('.');

            query[splitVal[0]] = splitVal[1] === 'string' ||
                splitVal[1] === 'boolean' ||
                    splitVal[1] === 'number' ? String(e.values[0]) : e.values.map(m => String(m));
        });

        return {
            id: el.id as string,
            name: el.name,
            query,
        };
    });
};

export const findSimilarPreset = (data: IPresetParams): IPresetParams[] => {
    const presets: IPresetParams[] = JSON.parse(JSON.stringify(store.getters['GET_REPORT_PRESETS']));
    return presets.filter(el => isEqual(data.query, el.query));
};

export const updateRouterFavoriteId = (id: string = 'nf'): void => {
    router.replace({
        params: {
            favorite_id: String(id),
        },
        query: {
            ...router.currentRoute.query,
        },
    });
};

export const setWindowTitle = (text: string): void => {
    window.document.title = text;
};
