var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"offer-redirects"},[_c('base-card',{staticClass:"offer-card-treking",attrs:{"title":"Исходящий редирект","comment":"Исходящий редирект - это оффер, на который перенаправляется трафик с данного оффера.","is-editable":true,"is-blue-tooltip":true},on:{"toggle":_vm.editInfo,"cancel":function($event){_vm.editMode = false}}},[_c('p',{staticClass:"base-list__text text-left"},[_vm._v(" Оффер для редиректа ")]),_c('p',{staticClass:"base-list__value"},[_c('span',[(_vm.editMode)?_c('base-autocomplete',{staticClass:"offer-card-treking__redirect",attrs:{"data":{
                        errorText: 'Оффер не найден',
                        apiMethod: _vm.getEmployeesOffersV2,
                        key: 'search',
                        itemValue: 'legacy_id',
                        clearable: true,
                        template: (item) => item.legacy_id + ' ' + item.name + _vm.getRedirectOfferStatus(item),
                        placeholder: 'Выберите оффер',
                    },"default-search":_vm.defaultSearchRedirectOffer,"is-return-object":true,"min-search-length":2},on:{"change":_vm.getRedirectOffer}}):(!_vm.editMode && _vm.offer.redirect_offer_id)?_c('router-link',{attrs:{"to":{ name: 'offer', params: { id: _vm.offer.redirect_offer_id }, query: { tab: '0' }}}},[_vm._v(" "+_vm._s(_vm.templateOffer)+" ")]):_c('span',[_vm._v("Не указан")])],1)])]),_c('base-card',{attrs:{"need-action-btn":false,"is-blue-tooltip":true,"title":"Входящий редирект","comment":"В таблице ниже показаны офферы, с которых настроен редирект на данный оффер."}},[_c('div',{staticClass:"offer-redirects__table"},[(!_vm.isOfferEmpty)?_c('table-with-front-pagination-design',{staticClass:"offers-goals__table",attrs:{"items":_vm.offer.redirectsToOffer,"headers":_vm.headers,"actions":_vm.actions,"routes":_vm.routes,"statuses":_vm.statuses,"items-per-page":50,"loading":false}}):_vm._e()],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }