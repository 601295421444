
import { Vue, Component } from "vue-property-decorator";
import OffersTable from "@/components/offers/offer/OffersTable.vue";
import FormMultiplyApproval from "@/components/offers/approvals/FormMultiplyApproval.vue";
import { getEmployeesOffersV2 } from "@/api/offers";
import TablePageWrapper from "@/components/layout/TablePageWrapper.vue";
import { IColumnFilter } from "@/types";
import { OffersColumns, OffersFilters } from "@/services/TablePage/offers";
import { Offer } from "@/services/offers/Offer";
import OfferStatistic from "@/components/offers/offer/OfferStatistic.vue";
import OfferPeriodSelect from "@/components/offers/offer/OfferPeriodSelect.vue";
import { downloadExcelFile, showServerError } from "@/utils";
import { Getter, namespace } from "vuex-class";
import { USER_ROLES } from "@/mappings/user-roles";
import { eventBus } from "@/eventbus";

const offerStore = namespace('offersModule');
const auth = namespace('authModule');

@Component({
    components: { TablePageWrapper },
})

export default class OffersList extends Vue {
    @Getter('GET_FILTERS_PARAMS_OBJECT') getFiltersParamsObject: any;
    @offerStore.State('statPeriodForList') statPeriod!: number;
    @auth.Getter('GET_ROLE') getUserRole!: USER_ROLES | null;

    filters: OffersFilters = new OffersFilters();
    columns: IColumnFilter[] = new OffersColumns().columns;
    table = {
        component: OffersTable,
        apiMethod: getEmployeesOffersV2,
        class: Offer,
        limit: 50,
    };
    downloadOptions = {
        loading: false,
    };
    loading = false;
    selectPeriod = OfferPeriodSelect;
    defaultSort = {
        sort: '',
        direction: '',
    };
    isShowMultiplyApprovalModal = false;
    selectedOffersIds: number[] = [];

    get additionalComponents(): any[] {
        return [
            {
                component: OfferStatistic,
                props: {},
            },
            {
                component: FormMultiplyApproval,
                props: {
                    showModalSync: this.isShowMultiplyApprovalModal,
                    selectedIds: this.selectedOffersIds,
                },
            },
        ];
    }

    get getCreateOfferForm(): {route: string} | void {
        const { route } = this.$router.resolve({ name: 'create-offer' });
        const routeRoles = route.meta?.roles! as USER_ROLES[];
        if (this.getUserRole === null) return;
        if (routeRoles.includes(this.getUserRole)) return { route: 'create-offer' };
    }

    created(): void {
        this.defaultSort.sort = this.$route.query.sort as string || 'legacy_id';
        this.defaultSort.direction = this.$route.query.direction as string || 'DESC';

        eventBus.$on('open-multiply-approval-modal', ( listIds: number[]) => {
            this.selectedOffersIds = listIds;
            this.isShowMultiplyApprovalModal = true;
        });
        eventBus.$on('close-multiply-approval-modal', () => {
            this.isShowMultiplyApprovalModal = false;
        });
    }

    beforeDestroy(): void {
        eventBus.$off('open-multiply-approval-modal');
        eventBus.$off('close-multiply-approval-modal');
    }

    async getExcel(): Promise<void> {
        const prepareParams = () => {
            const params = this.getFiltersParamsObject;
            const goalFields = ['goals_payout', 'goals_revenue'];
            const columns = this.$route.query.columns as string[];
            const isGoalsIncludes = goalFields.some(f => columns.includes(f));

            if (isGoalsIncludes) {
                params.fields = columns.filter(f => !goalFields.includes(f)).join(',');
                params.fields += ',goals';
            } else {
                params.fields = columns.join(',');
            }
            return params;
        };

        try {
            const file = await getEmployeesOffersV2(prepareParams(), { "Accept": "application/vnd.ms-excel" }, 'blob');
            downloadExcelFile(file, `Офферы`, 'xlsx');
        } catch (err) {
            showServerError(err, 'Файл не загружен');
        }
    }
}

