
import { Vue, Prop, Component } from 'vue-property-decorator';
import { ITableFooterButton } from "@/types";
import SvgPlay from "@/assets/icons/play.svg";
import SvgPause from "@/assets/icons/pause.svg";
import SvgCancelLight from "@/assets/icons/cancel-light.svg";
import SvgCheckSmall from "@/assets/icons/check-small.svg";
import SvgLogout from "@/assets/icons/logout.svg";
import BaseTooltip from "@/components_v3/base/formComponents/BaseTooltip.vue";
import BaseButton from "@/components_v3/base/formComponents/BaseButton.vue";

enum FooterButtonsIcons {
    'play' = SvgPlay,
    'pause' = SvgPause,
    'cancel' = SvgCancelLight,
    'check' = SvgCheckSmall,
    'logout' = SvgLogout
}

@Component({
    components: { BaseButton, BaseTooltip },
})
export default class TableFooterButtons extends Vue {
    @Prop({ required: true }) buttons!: ITableFooterButton[];

    Icon = FooterButtonsIcons;

    isShowDisabledText(btn: ITableFooterButton): boolean {
        return btn.disabled! && !!btn.disabledText;
    }
}
