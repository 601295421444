
import { Vue, Component, Prop } from "vue-property-decorator";
import AppTableDesign from "@/components/base/table/AppTableDesign.vue";
import { ITableFooterButton, TableHeader } from "@/types";
import { IBufferAccount } from "@/api/types/offers";
import ViewDateTime from "@/components/base/ViewDateTime.vue";
import { bufferHeadersTable, bufferRoutesTable } from "@/services/TablePage/bufferAccounts";
import { getNumEnding, showNotification, showServerError } from "@/utils";
import FormModal from "@/components/base/FormModal.vue";
import { deleteAffiliateFromBufferV2 } from "@/api/offers";

@Component({
    components: { FormModal, ViewDateTime, AppTableDesign },
})

export default class BufferAccountsTable extends Vue {
    @Prop({ required: true }) items!: IBufferAccount[];
    @Prop({ default: 100 }) limit!: number;
    @Prop({ default: 0 }) total!: number;
    @Prop({ default: true }) loading!: boolean;

    selected: IBufferAccount[] = [];
    isLoading: boolean = false;
    headers: TableHeader[] = bufferHeadersTable;
    routes = bufferRoutesTable;
    uniqueData = [
        { slot: 'item.created_at' },
    ];
    actions = [
        {
            title: 'Удалить',
            color: '#1B1B1D',
            icon: 'SvgTrashWhite',
            callback: ({ id }: {id: number}): void => {
                this.deletingItem.id = id;
            },
        },
    ];
    ends = [ 'аккаунт', 'аккаунта', 'аккаунтов' ];
    deletingItem: {id: number} = {
        id: 0,
    };

    get footerButtons(): ITableFooterButton[] {
        return [
            {
                text: `Удалить ${this.selected.length} ${getNumEnding(this.selected.length, this.ends)}`,
                textOfConfirm: 'Подтвердить?',
                color: 'red',
                icon: 'cancel',
                action: () => this.deleteAffiliates(this.selectedIds),
            },
        ];
    }

    get selectedIds(): number[] {
        return this.selected.map(i => i.id);
    }

    async deleteAffiliates(ids: number[] ): Promise<void> {
        try {
            await deleteAffiliateFromBufferV2(ids);
            this.submitData(0);

            showNotification('Указанные партнёры удалены из буферных аккаунтов.');
            this.deletingItem.id = 0;
            this.selected = [];
        } catch (err) {
            showServerError(err, 'Партнеры не удалены');
        }
    }

    submitData(offset: number, limit?: number): void {
        this.$emit('update-table', offset, limit);
    }
}

