import { IFilter } from '@/types';
import { IFilterDate } from '@/services/filters/filterParser';

export class FilterDateParser {
    protected filterDates: IFilterDate = {};

    constructor(filterDates: IFilterDate) {
        this.filterDates = filterDates;
    }

    parse(filter: IFilter): any {
        if (this.filterDates[filter.id].length === 1) {
            return {
                [`${filter.id}_from`]: this.filterDates[filter.id][0],
                [`${filter.id}_to`]: this.filterDates[filter.id][0],
            };
        } if (this.filterDates[filter.id].length > 1) {
            return this.sortDate(filter, this.filterDates);
        }
    }

    sortDate(filter: IFilter, filterDates: IFilterDate): any {
        if (typeof filterDates[filter.id] === 'string') {
            return {
                [filter.id]: filterDates[filter.id].length === 7
                    ? `${filterDates[filter.id]}-01`
                    : filterDates[filter.id],
            };
        }
        if (filterDates[filter.id].length > 1) {
            const [fromDate, toDate] = filterDates[filter.id];

            const from = new Date(fromDate);
            const to = new Date(toDate);
            const pickerType = filter.pickerType === 'month' ? 'month' : 'date';


            if (from.valueOf() > to.valueOf()) {
                return {
                    [`${filter.id}_from`]: this.correctDate(to, 'start', pickerType),
                    [`${filter.id}_to`]: this.correctDate(from, 'end', pickerType),
                };
            }

            return {
                [`${filter.id}_from`]: this.correctDate(from, 'start', pickerType),
                [`${filter.id}_to`]: this.correctDate(to, 'end', pickerType),
            };
        }
    }

    correctDate(date: Date, type: 'start' | 'end', pickerType: 'month' | 'date'): string {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const rawDay = date.getDate();
        const day = `${ rawDay < 10 ? '0' : '' }${rawDay}`;


        if (type === 'start') {
            const firstDay = pickerType === 'month' ? '01' : day;
            return `${year}-${month}-${firstDay}`;
        }

        const lastDay = pickerType === 'month' ?
            new Date(year, date.getMonth() + 1, 0).getDate() : day;
        return `${year}-${month}-${lastDay}`;
    }
}
