import { IColumnFilter, IFilter, TableHeader } from "@/types";
import { getActiveUserSearch } from "@/api/user";
import { translateLang, translateNotificationStatus } from "@/utils/translate";
import { getEmployeesOffersV2 } from "@/api/offers";
import { getAdvertisersSearch } from "@/api/revenue";
import { INotificationList } from "@/api/types/notifications";
import { CreatedFilters } from "@/services/filters/filters";
import { filterClass } from '@/services/filters/filterClass';

export const defaultColumns = ['category', 'event', 'lang', 'message', 'created_by', 'published_at'];
export const defaultAutoColumns = ['category', 'event', 'lang', 'message', 'created_at'];

export const notificationsFilterColumns: IColumnFilter[] = [
    {
        name: '',
        items: [
            { title: 'Категория', value: 'category' },
            { title: 'Подкатегория', value: 'event' },
            { title: 'Язык', value: 'lang' },
            { title: 'Текст уведомления',  value: 'message' },
            { title: 'Автор',  value: 'created_by' },
            { title: 'Дата публикации',  value: 'published_at' },
            { title: 'Статус',  value: 'is_deleted' },
            { title: 'Дата создания',  value: 'created_at' },
            { title: 'Дата обновления',  value: 'updated_at' },
        ],
    },
];

export const autoNotificationsFilterColumns: IColumnFilter[] = [
    {
        name: '',
        items: [
            { title: 'Категория', value: 'category' },
            { title: 'Подкатегория', value: 'event' },
            { title: 'Язык', value: 'lang' },
            { title: 'Текст уведомления',  value: 'message' },
            { title: 'Статус',  value: 'is_deleted' },
            { title: 'Дата создания',  value: 'created_at' },
        ],
    },
];

export class NotificationFilters extends CreatedFilters {
    constructor() {
        super();
    }

    getFilters(): IFilter[] {
        return [
            new filterClass.Autocomplete({
                col: 1,
                id: 'offer_id',
                apiMethod: getEmployeesOffersV2,
                key: 'search',
                label: 'Оффер',
                itemValue: 'legacy_id',
                itemText: 'name',
                select: [],
                items: [],
                multiple: true,
                multiPaste: true,
            }),
            new filterClass.Autocomplete({
                col: 1,
                id: 'authors',
                apiMethod: getActiveUserSearch,
                label: 'Автор',
                itemValue: 'id',
                itemText: 'name',
                select: [],
                items: [],
                multiple: true,
                template: (item: any): string => `${item.id} ${item.first_name} ${item.last_name}`,
            }),
            new filterClass.Autocomplete({
                col: 1,
                id: 'advertiser_id',
                apiMethod: getAdvertisersSearch,
                label: 'Рекламодатель',
                itemValue: 'id',
                itemText: 'name',
                select: [],
                items: [],
                multiple: true,
                multiPaste: true,
            }),
            new filterClass.Select({
                col: 1,
                id: 'lang',
                label: 'Язык',
                type: 'string',
                select: '',
                items: ['ru', 'en'],
                translate: (lang: string): string => translateLang(lang),
                clearable: true,
                noDataText: 'Нет данных',
                remove: true,
            }),
            new filterClass.Datepicker({
                col: 2,
                id: 'created',
                label: 'Дата создания',
                isComplexPicker: true,
                pickerType: 'date',
                range: true,
                select: [],
                clearable: true,
                isOnlyPlaceholder: false,
            }),
            new filterClass.Datepicker({
                col: 2,
                id: 'published',
                label: 'Дата публикации',
                isComplexPicker: true,
                pickerType: 'date',
                range: false,
                select: [],
                clearable: true,
                isOnlyPlaceholder: false,
            }),
            new filterClass.Checkbox({
                col: 2,
                label: 'Закрепление',
                id: 'is_pinned',
                select: [],
                itemText: 'true',
                type: 'string',
            }),
            new filterClass.Checkbox({
                col: 2,
                label: 'Показать удалённые',
                id: 'show_deleted',
                select: [],
                itemText: 'true',
                type: 'string',
            }),
        ];
    }
    // нужные фильтры для обновления урла, при добавлении офсета
    // updateUrl(filters: any): void {
    //     super.updateUrl(filters);
    // }
}

export const autoNotificationsHeaders: TableHeader[] = [
    { text: 'ID', sortable: false, value: 'id', align: 'left', width: '80px', fixed: true },
    { text: 'Категория', sortable: false, value: 'category', align: 'left', width: '125px' },
    { text: 'Подкатегория', sortable: false, value: 'event', align: 'left', width: '180px', class: '--event' },
    { text: 'Язык', sortable: false, value: 'lang', align: 'left', width: '55px', class: '--lang' },
    { text: 'Текст уведомления', sortable: false, value: 'message', align: 'left', class: '--message' },
    { text: 'Статус', sortable: false, value: 'is_deleted', align: 'left' },
    { text: 'Дата создания', sortable: false, value: 'created_at', align: 'right', width: '120px' },
];

export const notificationsHeaders: TableHeader[] = [
    { text: 'ID', sortable: false, value: 'id', align: 'center', width: '80px', fixed: true },
    { text: 'Категория', sortable: false, value: 'category', align: 'left', width: '125px' },
    { text: 'Подкатегория', sortable: false, value: 'event', align: 'left', width: '180px', class: '--event' },
    { text: 'Язык', sortable: false, value: 'lang', align: 'left', width: '55px' },
    { text: 'Текст уведомления', sortable: false, value: 'message', align: 'left', class: '--message' },
    { text: 'Автор', sortable: false, value: 'created_by', align: 'left', class: '--author', width: '180px' },
    { text: 'Дата публикации', sortable: false, value: 'published_at', align: 'right', width: '125px' },
    { text: 'Статус', sortable: false, value: 'is_deleted', align: 'left' },
    { text: 'Дата создания', sortable: false, value: 'created_at', align: 'right', width: '125px' },
    { text: 'Дата обновления', sortable: false, value: 'updated_at', align: 'right', width: '125px' },
];

export const timeDate = [
    {
        slot: 'item.published_at',
        key: 'published_at',
        isLine: false,
    },
    {
        slot: 'item.created_at',
        key: 'created_at',
        isLine: false,
    },
    {
        slot: 'item.updated_at',
        key: 'updated_at',
        isLine: false,
    },
];

export const statuses = [
    {
        slot: 'item.is_deleted',
        key: 'is_deleted',
        translate: translateNotificationStatus,
    },
];

export const uniqueDate = [
    { slot: 'item.id' },
    { slot: 'item.lang' },
    { slot: 'item.message' },
    {
        slot: 'item.created_by',
        template: (item: INotificationList): string => item.created_by ?  item.created_by.name! : '',
    },
];
export const tableIDRoute = {
    routeParam: 'id',
    slot: 'item.id',
    routerName: 'notification-detail',
    text: 'id',
    getParams(item:any): {id: string} {
        return {
            id: item.id!,
        };
    },
    template: (item: any): string => item.id,
};

export const offersTableDetail: TableHeader[] = [
    { text: 'ID', sortable: false, value: 'legacy_id', align: 'center', width: '80px' },
    { text: 'Оффер', sortable: false, value: 'name', align: 'left' },
    { text: 'Рекламодатель', sortable: false, value: 'advertiser', align: 'left' },
    { text: 'Менеджер', sortable: false, value: 'manager', align: 'left' },
    { text: 'Статус', sortable: false, value: 'status', align: 'left', width: '130px' },
    { text: '', sortable: false, value: 'actions', align: 'center', width: '40px' },
];

export const affiliateTableDetail: TableHeader[] = [
    { text: 'ID', sortable: false, value: 'account_id', align: 'center', width: '80px' },
    { text: 'Партнёр', sortable: false, value: 'name', align: 'left' },
    { text: 'Почта', sortable: false, value: 'email', align: 'left' },
    { text: 'Менеджер', sortable: false, value: 'manager', align: 'left' },
    { text: 'Статус', sortable: false, value: 'status', align: 'left',  width: '130px' },
    { text: '', sortable: false, value: 'actions', align: 'center', width: '40px' },
];
