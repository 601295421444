var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"base-table__wrap"},[(_vm.headers.length)?_c('table',{staticClass:"base-table"},[_vm._t("header"),_c('thead',{staticClass:"base-table__head"},[_c('tr',[(_vm.showSelect)?_c('th',{staticClass:"section-divider"},[_c(_setup.BaseCheckbox,{attrs:{"model-value":_setup.isAllItemsSelected,"indeterminate":_setup.isNotNullSelectedItems},on:{"update:modelValue":_setup.toggleSelectAll}})],1):_vm._e(),_vm._l((_vm.headers),function(header,headerIdx){return _c('th',{key:header.value,class:[
                        header.align ? 'text-' + header.align : '',
                        _setup.addDivider(headerIdx) ? 'section-divider' : '',
                        header.class ? header.class : '',
                    ],style:(_setup.getHeaderWidth(header.width))},[(_vm.$slots[`header.${header.value}`])?[_vm._t(`header.${header.value}`,null,{"header":header})]:(_vm.$slots[`header.actions`])?[_c(_setup.SvgActions)]:_c('span',[_vm._v(" "+_vm._s(header.text)+" ")])],2)})],2)]),_c('tbody',{staticClass:"base-table__body"},[_vm._t("body.prepend"),_vm._l((_vm.items),function(item){return _c('tr',{key:item.id,class:_setup.props.addRowClass ? _setup.getRowClass(item) : _setup.ROW_DEFAULT_CLASS},[(_vm.showSelect)?_c('td',{staticClass:"section-divider"},[_c(_setup.BaseCheckbox,{attrs:{"model-value":_setup.selectedItems.includes(item),"readonly":item.isSelectable === false,"disabled":item.isSelectable === false},on:{"update:modelValue":(newVal) => _setup.toggleSelection(item, newVal)}})],1):_vm._e(),_vm._l((_vm.headers),function(header,headerIdx){return _c('td',{key:header.value,class:[
                        header.align ? 'text-' + header.align : '',
                        _setup.addDivider(headerIdx) ? 'section-divider' : ''
                    ]},[(header.value === 'verticals')?_vm._l((item.verticals),function(vertItem){return _c('div',{key:vertItem.value},[_vm._v(" "+_vm._s(_setup.translateVertical(vertItem.name))+" ")])}):_vm._e(),(item[header.value]?.component)?_c('div',[_c(item[header.value].component,_vm._b({tag:"component"},'component',item[header.value].componentData,false))],1):_vm._t('item.' + header.value,function(){return [_vm._v(" "+_vm._s(_setup.getNestedValue(item, header.value))+" ")]},{"item":item})],2)})],2)}),_vm._t("body.append"),(!_vm.items.length && !_vm.loading)?_c('tr',{staticClass:"base-table__no-data"},[_c('td',{attrs:{"colspan":_vm.showSelect ? _vm.headers.length + 1 : _vm.headers.length}},[_c('div',{staticClass:"text-center"},[_c('v-icon',{attrs:{"small":"","color":"error"}},[_vm._v(" report_problem ")]),(_setup.isFiltered)?_c('span',[_vm._v(" "+_vm._s(_vm.noResultText))]):_c('span',[_vm._v(" "+_vm._s(_vm.noDataText))])],1)])]):_vm._e()],2),_c('tfoot',{staticClass:"base-table__footer"},[_vm._t("tfoot"),(_vm.isShowPagination)?_c('tr',{staticClass:"base-table__pagination"},[_c('td',{attrs:{"colspan":_vm.showSelect ? _vm.headers.length + 1 : _vm.headers.length}},[_c(_setup.BasePagination,{attrs:{"items-per-page":_vm.itemsPerPage,"total":_vm.total,"page":_setup.page},on:{"update:page":_setup.updatePage}})],1)]):_vm._e()],2)],2):_vm._e(),(_vm.loading)?_c(_setup.LineLoader):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }