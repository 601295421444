import SvgTelegram from "@/assets/icons/telegram.svg";
import SvgWhatsapp from "@/assets/icons/whatsapp.svg";
import SvgSkype from "@/assets/icons/skype.svg";
import SvgWeChat from "@/assets/icons/wechat.svg";

export enum MESSENGER {
    TELEGRAM = 'telegram',
    SKYPE = 'skype',
    WHATSAPP = 'whatsapp',
    WECHAT = 'wechat'
}

export interface IMessengerItem {
    type: MESSENGER;
    logo: SVGElement;
}

export const messengerItems: IMessengerItem[] = [
    { type: MESSENGER.TELEGRAM, logo: SvgTelegram },
    { type: MESSENGER.WHATSAPP, logo: SvgWhatsapp },
    { type: MESSENGER.SKYPE, logo: SvgSkype },
    { type: MESSENGER.WECHAT, logo: SvgWeChat },
];
