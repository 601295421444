var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('base-card',{staticClass:"payout-method__requisites",attrs:{"title":"Реквизиты"}},[_c('base-list',{staticClass:"base__list-custom-class"},[_vm._l((_vm.keys),function(value,name){return _c('base-list-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowFields(name)),expression:"isShowFields(name)"}],attrs:{"label":_vm.translatePaymentsRequisite(name)}},[(name !== 'legal_id')?_c('v-text-field',{staticClass:"input",attrs:{"disabled":_vm.isDisabledField,"label":""},on:{"change":function($event){return _vm.changeFieldValue(name, $event)}},model:{value:(_vm.keys[name]),callback:function ($$v) {_vm.$set(_vm.keys, name, $$v)},expression:"keys[name]"}}):_c('base-autocomplete',{attrs:{"default-search":_vm.search,"data":{
                    errorText: 'Вебмастера не найдены',
                    apiMethod: _vm.searchWebmasters,
                    itemValue: 'guid',
                    itemText: 'name',
                    template: (item) => item.name,
                    placeholder: 'Введите id или название организации',
                    rules: [(v) => !!v || 'Поле обязательно для заполнения'],
                    clearable: true,
                    disabled: _vm.isDisabledField,
                }},on:{"change":function($event){return _vm.changeFieldValue(name, $event)}},model:{value:(_vm.keys[name]),callback:function ($$v) {_vm.$set(_vm.keys, name, $$v)},expression:"keys[name]"}})],1)}),_vm._l((_vm.methodData),function(value,name){return _c('base-list-item',{directives:[{name:"show",rawName:"v-show",value:(name !== 'legal_id'),expression:"name !== 'legal_id'"}],attrs:{"label":_vm.translatePaymentsRequisite(name)}},[_vm._v(" "+_vm._s(value)+" ")])})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }