
import { Vue, Component, PropSync, Prop, Watch } from "vue-property-decorator";
import SvgRangeCalendar from '@/assets/icons/range-calendar.svg';
import { formatDateInputPicker, getLastDayOfMonth } from "@/utils/formatDates";

@Component({
    components: { SvgRangeCalendar },
})

export default class MonthPickerRange extends Vue {
    @PropSync('value', { required: true }) valueSync!: string[];
    @Prop({ type: String, default: '' }) readonly label!: string;

    pickerValue: string[] = ['', ''];

    get getValue(): string {
        return this.valueSync[0] && this.valueSync[1] ?
            `${formatDateInputPicker(this.valueSync[0], 'LLLL yyyy')} - ${formatDateInputPicker(this.valueSync[1], 'LLLL yyyy')}` : '';
    }

    @Watch('valueSync', { deep: true })
    setPickerValue(): void {
        this.pickerValue = this.formatPickerValue(this.valueSync);
    }

    changePickerValue(months: string[]): void {
        this.valueSync = this.formatPickerValue(months);
    }

    inputMenu(event: boolean): void {
        if (!event) {
            this.$emit('update', this.valueSync);
        }
    }

    clear(): void {
        this.valueSync = [];
        this.pickerValue = [];
        this.$emit('clear-date');
    }

    formatPickerValue(value: string[]): string[] {
        let date: string[] = ['', ''];

        if (value[0].length === 10) {
            date = value.map(el => (formatDateInputPicker(el, 'yyyy-MM')));
        } else {
            if (value.length === 1) {
                const lastDay = getLastDayOfMonth(value[0]).getDate();
                date = [value[0] + '-01', value[0] + `-${lastDay}`];
            }

            if (value.length === 2) {
                const lastDay = getLastDayOfMonth(value[1]).getDate();
                date = [value[0] + '-01', value[1] + `-${lastDay}`];
            }
        }

        return date.sort((a, b) => (
            new Date(a).getTime() - new Date(b).getTime()
        ));
    }
}
