import { ICreateAffiliate, IMessenger, IUser } from "@/api/types/user";
import { IBaseAutocomplete } from "@/types";
import { createEmployeeAffiliate, getActiveUserSearch, getAffiliateSearch, getTrafficSource } from "@/api/user";
import { translatePartnerStatus } from "@/utils/translate";
import { getEmployeesOfferVerticals } from "@/api/offers";
import { showNotification, showServerError } from "@/utils";
import store from "@/store";
import router from "@/router";
import { subYears } from 'date-fns';
import { addNewSlaveForMasterAccount, getPayoutMasterAccounts, makeNewMasterAccount } from "@/api/payout";

export enum AFFILIATE_FIELD {
    TEXT = 'text',
    PASSWORD = 'password',
    SELECT = 'select',
    DATE = 'date',
    TAG_SELECT = 'tag',
    MESSENGER = 'messenger',
    PHONE = 'phone'
}

export interface IAffiliateField {
    text: string;
    property: string;
    type: AFFILIATE_FIELD;
    validateRule?: any;
}

interface ISelectField {
    [key: string]: {
        data: IBaseAutocomplete,
        items?: any
        api?: () => Promise<any>
    }
}

const passwordRegexp = new RegExp('(?=.*[A-Za-z]|[0-9]|[#?!@$()%^&*-])(?!.*[А-Яа-яЁё ]).{8,16}$');
const emailRegexp = new RegExp('^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$');
const date18YearsAgo = subYears(new Date(), 18);

const VALIDATION_RULES = {
    TEXT: (v) => !!v,
    PASSWORD: (v) => !!v && passwordRegexp.test(v),
    EMAIL: (v) => !!v && emailRegexp.test(v),
    ARRAY: (v) => !!v && (Array.isArray(v) && v.length > 0),
    BIRTHDAY: () => { return { start: null, end: date18YearsAgo }; },
};

export class CreateAffiliate implements ICreateAffiliate {
    account_manager_id: number;
    first_name: string;
    last_name: string;
    nick_name: string;
    status: string;
    email: string;
    password: string;
    messenger: IMessenger;
    birthday: string;
    phone: string;
    country: string;
    city: string;
    language: string;
    master_id: number;
    traffic_source_ids: number[];
    vertical_ids: number[];
    tag_ids: number[];

    constructor(affiliate: ICreateAffiliate) {
        this.account_manager_id = affiliate.account_manager_id || this.getDefaultManager;
        this.first_name = affiliate.first_name || '';
        this.last_name = affiliate.last_name || '';
        this.nick_name = affiliate.nick_name || '';
        this.status = affiliate.status || '';
        this.email = affiliate.email || '';
        this.password = affiliate.password || '';
        this.messenger = affiliate.messenger || { type: '', account: '' };
        this.birthday = affiliate.birthday || '';
        this.phone = affiliate.phone || '';
        this.country = affiliate.country || '';
        this.city = affiliate.city || '';
        this.language = affiliate.language || '';
        this.master_id = affiliate.master_id;
        this.traffic_source_ids = affiliate.traffic_source_ids || [];
        this.vertical_ids = affiliate.vertical_ids || [];
        this.tag_ids = affiliate.tag_ids || [];
    }

    get getOptions(): { [key: string]: IAffiliateField[] } {
        return {
            main: [
                { text: 'Имя', property: 'first_name', type: AFFILIATE_FIELD.TEXT, validateRule: VALIDATION_RULES.TEXT },
                { text: 'Фамилия',property: 'last_name',type: AFFILIATE_FIELD.TEXT,validateRule: VALIDATION_RULES.TEXT },
                { text: 'Никнейм', property: 'nick_name', type: AFFILIATE_FIELD.TEXT },
                { text: 'Пароль',property: 'password',type: AFFILIATE_FIELD.PASSWORD,validateRule: VALIDATION_RULES.PASSWORD },
                { text: 'Менеджер', property: 'account_manager_id', type: AFFILIATE_FIELD.SELECT, validateRule: VALIDATION_RULES.TEXT },
                { text: 'Мастер-аккаунт', property: 'master_id', type: AFFILIATE_FIELD.SELECT },
                { text: 'Статус', property: 'status', type: AFFILIATE_FIELD.SELECT, validateRule: VALIDATION_RULES.TEXT },
            ],
            traffic: [
                { text: 'Вертикаль', property: 'vertical_ids', type: AFFILIATE_FIELD.SELECT, validateRule: VALIDATION_RULES.ARRAY },
                { text: 'Источник трафика', property: 'traffic_source_ids', type: AFFILIATE_FIELD.SELECT },
            ],
            contacts: [
                { text: 'E-mail', property: 'email', type: AFFILIATE_FIELD.TEXT, validateRule: VALIDATION_RULES.EMAIL },
                { text: 'Телефон', property: 'phone', type: AFFILIATE_FIELD.PHONE },
                { text: 'Мессенджер', property: 'messenger', type: AFFILIATE_FIELD.MESSENGER },
            ],
            other: [
                { text: 'Язык', property: 'language', type: AFFILIATE_FIELD.SELECT, validateRule: VALIDATION_RULES.TEXT },
                { text: 'Теги', property: 'tag_ids', type: AFFILIATE_FIELD.TAG_SELECT },
                { text: 'Страна', property: 'country', type: AFFILIATE_FIELD.SELECT },
                { text: 'Город', property: 'city', type: AFFILIATE_FIELD.TEXT },
                { text: 'Дата рождения', property: 'birthday', type: AFFILIATE_FIELD.DATE, validateRule: VALIDATION_RULES.BIRTHDAY },
            ],
        };
    }

    getSelectData: ISelectField = {
        account_manager_id: {
            data: {
                apiMethod: getActiveUserSearch,
                template: (i) => `${i.id} ${i.first_name} ${i.last_name}`,
                itemValue: 'id',
                rules: [VALIDATION_RULES.TEXT],
            },
        },
        master_id: {
            data: {
                apiMethod: getAffiliateSearch,
                template: (i) => `${i.id} ${i.first_name} ${i.last_name}`,
                itemValue: 'id',
            },
        },
        status: {
            data: { itemText: 'text', itemValue: 'id', rules: [VALIDATION_RULES.TEXT] },
            items: this.getStatusItems,
        },
        vertical_ids: {
            data: { itemText: 'name', itemValue: 'id', multiple: true, rules: [VALIDATION_RULES.ARRAY] },
            api: getEmployeesOfferVerticals,
        },
        traffic_source_ids: {
            data: { itemText: 'name', itemValue: 'id', multiple: true },
            api: getTrafficSource,
        },
        language: {
            data: { itemText: 'text', itemValue: 'id', rules: [VALIDATION_RULES.TEXT] },
            items: [{ id: 'ru', text: 'Русский' }, { id: 'en', text: 'English' }],
        },
        country: {
            data: { itemText: 'name', itemValue: 'code' },
            items: store.getters['catalogueModule/GET_COUNTRIES'],
        },
        city: {
            data: { itemText: 'text', itemValue: 'id' },
            items: [],
        },
    };

    get getStatusItems(): { id: string, text: string }[] {
        const items = [{ id: 'active' }, { id: 'pending' }, { id: 'deleted' }, { id: 'blocked' }, { id: 'rejected' }];
        return items.map(i => ({ id: i.id, text: translatePartnerStatus(i.id).text }));
    }

    get getDefaultManager(): number {
        const user = store.getters['authModule/GET_USER'] as IUser;
        return user.account_id!;
    }

    async getAllItems(): Promise<void> {
        await Promise.all(Object.values(this.getSelectData).map(async item => item.api && (item.items = await item.api())))
            .catch((err) => showServerError(err));
    }

    private get getFields(): IAffiliateField[] {
        return Object.values(this.getOptions).flat().filter(item => !!item.validateRule);
    }

    get isDisableCreate(): boolean {
        const isValidFields = this.getFields.every(field =>
            field.validateRule === undefined ? true : field.validateRule(this[field.property]));
        return !isValidFields;
    }

    get getCountRequiredFields(): number {
        return this.getFields.filter(item => !item.validateRule(this[item.property])).length;
    }

    get isShowPasswordHint(): boolean {
        const passwordOpt = this.getFields.find(item => item.property === 'password');
        return !!passwordOpt ? !passwordOpt.validateRule(this.password) : false;
    }

    async setMaster(id: number): Promise<void> {
        const params = { offset: 0, limit: 100, affiliate_ids: [this.master_id] };
        const master = await getPayoutMasterAccounts(params);
        if (master.data.length > 0) {
            await addNewSlaveForMasterAccount(master.data[0].id, { slaves: [id] });
        } else {
            const data = { master: { id: this.master_id }, slaves: [id] };
            await makeNewMasterAccount(data);
        }
    }

    async createAffiliate(): Promise<void> {
        const getDto = () => {
            const dto = Object.fromEntries(Object.entries(this));

            const isDelete = (key: string) => {
                return ['master_id', 'getSelectData'].includes(key) || !dto[key] ||
                    (Array.isArray(dto[key]) && dto[key].filter(Boolean).length === 0) ||
                    (key === 'messenger' && (dto[key].account === '' || dto[key].type === ''));
            };

            for (const key in dto) {
                isDelete(key) && (delete dto[key]);
            }
            return dto;
        };

        try {
            const dto= getDto();
            const { id } = await createEmployeeAffiliate(dto as ICreateAffiliate);
            if (this.master_id) await this.setMaster(id);
            showNotification('Партнёр успешно создан');
            await router.push({ name: 'affiliates-list', query: { manager_ids: `${this.account_manager_id!}` } });
        } catch (err) {
            showServerError(err, 'Ошибка создания партнёра');
        }
    }
}


