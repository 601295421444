var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('form-modal',{attrs:{"custom-class":"buffer-create-form base-form","show-modal":_vm.showModalSync,"max-width":"628","title":"Добавить партнёра в буфер","need-hide-btn":_vm.isForceSend,"closable":true,"is-disabled":!_vm.isValidBufferAccount,"name-btn-save":_vm.isForceSend ? 'Перезаписать' : 'Сохранить',"is-need-already-click":false},on:{"close-modal":_vm.closeModal,"hide-modal":_vm.closeModal,"save":_vm.updateBuffer}},[[_c('div',{staticClass:"base-form__form buffer-create-form__form"},[_c('p',{staticClass:"base-form__item-label"},[_vm._v(" Буффер ")]),_c('base-autocomplete',{ref:"bufferInput",staticClass:"base-form__item-input",attrs:{"data":{
                    placeholder: 'Буферный аккаунт',
                    errorText: 'Буферы не найден',
                    itemText: 'id',
                    itemValue: 'id',
                    clearable: false,
                    template: (item) => item.id,
                    rules: [(v) => !!v || 'Поле обязательно для заполнения'],
                    chipWidth: 132
                },"possible-items":_vm.buffers},on:{"change":(item) => { _vm.updatableBuffer.buffer_account_id = item; _vm.clearErrors(); }}}),_c('p',{staticClass:"base-form__item-label"},[_vm._v(" Партнёр ")]),_c('base-autocomplete',{ref:"affiliateInput",staticClass:"base-form__item-input",attrs:{"data":{
                    errorText: 'Партнер не найден',
                    apiMethod: _vm.getAffiliateSearch,
                    itemValue: 'id',
                    template: (item) => `${item.id} ${item.first_name} ${item.last_name}`,
                    placeholder: 'Введите ID или имя партнёра',
                    clearable: false,
                    rules: [(v) => !!v || 'Поле обязательно для заполнения'],
                    multiple: true,
                    multiPaste: true,
                    chipWidth: 132
                },"also-search-by":"email"},on:{"change":(item) => { _vm.updatableBuffer.affiliates_ids = item; _vm.clearErrors(); }}}),_c('p',{staticClass:"base-form__item-label"},[_vm._v(" Оффер ")]),_c('base-autocomplete',{ref:"offersInput",staticClass:"base-form__item-input",attrs:{"data":{
                    errorText: 'Оффер не найден',
                    apiMethod: _vm.getEmployeesOffersV2,
                    key: 'search',
                    itemValue: 'legacy_id',
                    template: (item) => item.legacy_id + ' ' + item.name,
                    placeholder: 'Введите ID или название оффера',
                    clearable: false,
                    rules: [(v) => !!v || 'Поле обязательно для заполнения'],
                    multiple: true,
                    multiPaste: true,
                    chipWidth: 132
                }},on:{"change":(item) => { _vm.updatableBuffer.offers_ids = item; _vm.clearErrors(); }}})],1),(_vm.isForceSend)?_c('section',{staticClass:"buffer-create-form__errors"},[_c('div',{staticClass:"buffer-create-form__errors-title"},[_c('SvgFalse',{staticClass:"mr-2"}),_c('h4',[_vm._v("Уже добавлено")])],1),_c('ul',{staticClass:"buffer-create-form__errors-list"},[_vm._l((_vm.conflictingPairs),function(item){return [(item)?_c('li',{staticClass:"buffer-create-form__errors-item"},[_vm._v(" "+_vm._s(`Партнёр ${item.affiliate} и оффер ${item.offer} в буфере ${item.buffer}`)+" ")]):_vm._e()]})],2)]):_vm._e(),(_vm.isLoading)?_c('loader'):_vm._e()]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }