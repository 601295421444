
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import { OfferSingle } from "@/services/offers/OfferSingle";
import { translateBoolean } from "@/utils/translate";
import { IEditableOfferFields } from "@/api/types/offers";
import { eventBus } from "@/eventbus";
import BaseCard from "@/components/base/BaseCard.vue";
import QuestionMarkTooltip from "@/components/base/QuestionMarkTooltip.vue";

interface IOption {
    id: string;
    name: string;
    value: any;
    sup?: string;
    isEditable: boolean;
    tooltip?: string;
}

@Component({
    components: {
        BaseCard,
        QuestionMarkTooltip,
    },
})
export default class OfferCardSettings extends Vue {
    @Prop({ required: true }) offer!: OfferSingle;
    @PropSync('isLoading') isLoadingSync!: boolean;

    isEdit = false;
    offerData: IEditableOfferFields = {} as IEditableOfferFields;
    translateBoolean = translateBoolean;

    get getOptions(): IOption[] {
        return [
            {
                id: 'private',
                name: 'Приватность',
                value: this.offer.private,
                isEditable: true,
            },
            {
                id: 'requires_approval',
                name: 'Требует подтверждения',
                sup: 'confirmations',
                value: this.offer.confirmations.requires_approval,
                isEditable: true,
            },
            {
                id: 'requires_advertiser_approval',
                name: 'Требует согласования',
                sup: 'confirmations',
                value: this.offer.confirmations.requires_advertiser_approval,
                isEditable: true,
            },
            {
                id: 'offer_for_clicks',
                name: 'Лиды из кликов',
                value: this.offer.offer_for_clicks,
                isEditable: true,
                tooltip: 'При включении этой опции, для данного оффера будут автоматически создаваться конверсии из уникальных кликов.',
            },
        ];
    }

    editInfo(edit: boolean): void {
        if (edit) {
            this.setValues();
        } else {
            this.updateOffer();
        }
        this.isEdit = edit;
    }

    cancelEdit(): void {
        this.isEdit = false;
    }

    setValues(): void {
        this.getOptions.forEach(opt => {
            this.offerData[opt.id] = opt.sup ? this.offer[opt.sup][opt.id] : this.offer[opt.id];
        });
    }

    async updateOffer(): Promise<void> {
        this.isLoadingSync = true;
        const isUpdate = await this.offer.editOffer(this.offerData);
        if (isUpdate) eventBus.$emit("update-offer");
        this.isLoadingSync = false;
    }
}
