
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import { eventBus } from "@/eventbus";
import Status from "@/components/base/Status.vue";
import BaseSelect from "@/components/base/design/BaseSelect.vue";
import { translateBoolean, translateTwoFactor } from "@/utils/translate";
import { IUser } from "@/api/types/user";
import { patchUserByTypeV3 } from "@/api/user";
import { showServerError } from "@/utils";
import { namespace } from 'vuex-class';
import BaseCard from "@/components/base/BaseCard.vue";
import { MESSENGER } from '@/mappings/messengers';

const auth = namespace('authModule');

@Component({
    methods: { translateTwoFactor, translateBoolean },
    components: {
        BaseCard,
        BaseSelect,
        Status,
    },
})
export default class UserCardMessengers extends Vue {
    @Prop({ required: true }) user!: IUser;
    @PropSync('isLoading') isLoadingSync!: boolean;
    @auth.Getter('GET_USER') currentUser;
    MESSENGER = MESSENGER;
    isEdit = false;
    userData: any = {};

    get getOptions(): any[] {
        return [
            {
                name: 'Skype',
                value: this.userData.skype,
                type: MESSENGER.SKYPE,
                updateUserData: (newValue) => this.userData.skype = newValue,
            },
            {
                name: 'Telegram',
                value: this.userData.telegram,
                type: MESSENGER.TELEGRAM,
                updateUserData: (newValue) => this.userData.telegram = newValue,
            },
            {
                name: 'WhatsApp',
                value: this.userData.whatsapp,
                type: MESSENGER.WHATSAPP,
                updateUserData: (newValue) => this.userData.whatsapp = newValue,
            },
            {
                name: 'WeChat',
                value: this.userData.wechat,
                type: MESSENGER.WECHAT,
                updateUserData: (newValue) => this.userData.wechat = newValue,
            },
        ];
    }

    getMessengerAccount(type: string): string {
        const messenger = this.user.messengers?.find((item) => item.type === type);
        return messenger ? messenger.account : '';
    }

    editInfo(edit: boolean): void {
        if (edit) {
            this.setDefaults();
        } else {
            this.updateAllUserData();
        }
        this.isEdit = edit;
    }

    cancelEdit(): void {
        this.isEdit = false;
    }

    setDefaults(): void {
        this.userData.telegram = this.getMessengerAccount(MESSENGER.TELEGRAM);
        this.userData.whatsapp = this.getMessengerAccount(MESSENGER.WHATSAPP);
        this.userData.skype = this.getMessengerAccount(MESSENGER.SKYPE);
        this.userData.wechat = this.getMessengerAccount(MESSENGER.WECHAT);
    }

    created(): void {
        this.setDefaults();
    }

    async updateUser(): Promise<void> {
        const accountId = this.user.id as number;
        try {
            this.user.messengers = [
                { type: MESSENGER.TELEGRAM, account: this.userData.telegram },
                { type: MESSENGER.WHATSAPP, account: this.userData.whatsapp },
                { type: MESSENGER.SKYPE, account: this.userData.skype },
                { type: MESSENGER.WECHAT, account: this.userData.wechat },
            ];
            const messengers = this.user.messengers.filter(i => i.account);
            await patchUserByTypeV3(accountId, this.user.account_type!,
                { messengers });
        } catch (err) {
            showServerError(err, 'Ошибка сохранения');
        }
    }

    async updateAllUserData(): Promise<void> {
        this.isLoadingSync = true;
        await this.updateUser();
        eventBus.$emit("update-user");
        this.isLoadingSync = false;
    }
}
