
import { Vue, Component, Ref } from "vue-property-decorator";
import PageLayout from "@/components/layout/PageLayout.vue";
import Status from "@/components/base/Status.vue";
import PaymentDetail from "@/components/affiliate/payments/PaymentDetail.vue";
import SvgCancel from '@/assets/icons/cancel-white.svg';
import SvgCheck from '@/assets/icons/check.svg';
import { namespace } from "vuex-class";
import { showServerError } from "@/utils";
import { getOneEmployeesOffer } from "@/api/offers";
import { OfferSingle } from "@/services/offers/OfferSingle";
import GoalMain from "@/components/offers/offer/goal/GoalMain.vue";
import { eventBus } from "@/eventbus";
import GoalPayoutAndRevenue from "@/components_v3/offers/offer/goal/GoalPayoutAndRevenue.vue";
import GoalTracking from "@/components/offers/offer/goal/GoalTracking.vue";
import { IOfferGoal } from "@/api/types/offers";

const auth = namespace('authModule');
@Component({
    components: { GoalTracking, GoalPayoutAndRevenue, GoalMain, PaymentDetail, Status, PageLayout, SvgCheck, SvgCancel },
})

export default class Goal extends Vue {
    @auth.Getter('GET_USER') user;
    @Ref() detail!: PaymentDetail;

    goalID: string = '';
    offerID: string = '';
    offer: OfferSingle | null = null;
    goal: IOfferGoal | null = null;

    get getTitle(): string {
        return `Оффер ${this.offerID} / Цель ${this.goal ? this.goal.legacy_id : ''} ${this.goal ? this.goal.name : ''}`;
    }

    created(): void {
        this.goalID = this.$route.params.id;
        this.offerID = this.$route.query.offer_id.toString();
        eventBus.$on("update-goal", this.getOfferById);
        this.getOfferById();
    }

    async getOfferById(): Promise<void> {
        try {
            const data = await getOneEmployeesOffer(this.offerID);
            this.offer = new OfferSingle(data);
            this.goal = this.offer.goals.find((item) => item.id === Number(this.goalID))!;
            document.title = this.getTitle;
        } catch (err) {
            showServerError(err, 'Не удалось получить оффер');
        }
    }

    beforeDestroy(): void {
        eventBus.$off("update-goal", this.getOfferById);
    }
}
