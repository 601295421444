
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
})

export default class PageTabs extends Vue {
    @Prop({ required: true }) tab!: number;
    @Prop({ default: () => ({ name: 'tab', type: 'number' }) } ) tabKey!: { name: string, type: string };
    @Prop({ required: true }) tabItems!: any[];
    @Prop({ default: true }) isWithHistory!: boolean;

    // сохраненная история вкладок, для правильного поведения на нажатие кнопки назад в браузере
    historyTabs: Array<{tab: number, columns: any}> = [];

    created(): void {
        window.addEventListener("popstate", () => {
            const backTab = this.historyTabs[0];
            if (!backTab) return;
            this.changeTab(backTab.tab, false);
            // костыль для обновления columns, иначе проставляются дефолтны колонки
            // или колонки предыдущего таба( Из-за вотчеров в компонентах страниц)
            const query = {
                ...this.$route.query,
                ...{ [this.tabKey.name]: this.getTab(backTab.tab), columns: backTab.columns },
            };
            // @ts-ignore
            this.$router.replace({ name: this.$route.name, query }).catch((error) => {
                if (error.name !== 'NavigationDuplicated') {
                    throw error;
                }
            });
            this.historyTabs.shift();
        });
    }

    getTab(tab: number): any {
        return this.tabKey.type === 'number' ? tab : !!tab;
    }

    changeTab(tab: number, isHistory = this.isWithHistory): void {
        if (isHistory) {
            const query = { ...this.$route.query, ...{ [this.tabKey.name]: this.getTab(this.tab) } };
            // @ts-ignore
            const url = new URL(location);
            for (const key in query) {
                url.searchParams.set(key, query[key]);
            }
            history.pushState({ [this.tabKey.name]: this.getTab(this.tab)  }, "", url);
            if (this.tab >= 0) {
                this.historyTabs.unshift({ tab: this.tab, columns: this.$route.query.columns });
            }
        }
        this.$emit('change', tab, isHistory, this.historyTabs[0]?.columns);
    }
}
