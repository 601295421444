import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, Mutation, namespace } from 'vuex-class';
import { showServerError } from '@/utils';
import { SEARCH_VALUE_DEBOUNCE } from '@/configs/global';
import { IFilter } from '@/types';
import { CreatedFilters } from '@/services/filters/filters';
import { xorBy, uniqBy } from "lodash-es";
import { ICountry } from "@/api/types/catalogue";

const catalogue = namespace('catalogueModule');

@Component({})

export default class FiltersMixin extends Vue {
    @catalogue.Getter('GET_COUNTRIES') countries!: ICountry[];
    @Mutation('SET_FILTERS_PARAMS_OBJECT') setFiltersParamsObject!: (payload: any) => void;
    @Getter('GET_DEFAULT_FILTERS_PARAMS_OBJECT') getDefaultFiltersParams!: any;
    @Getter('GET_FILTERS_PARAMS_OBJECT') getFiltersParamsObject!: any;

    @Prop({ default: false }) readonly isGroupBy!: boolean;
    @Prop({ required: false }) readonly disabledSubmit!: boolean;
    @Prop({ type: String, default: 'Применить' }) readonly successBtnText!: string;
    @Prop({ type: String, default: 'Сбросить' }) readonly cancelBtnText!: string;
    @Prop({ type: String, default: 'Фильтры' }) readonly title!: string;
    @Prop({ type: Number, default: 4 }) readonly sizeLG!: number;
    @Prop({ type: Number, default: 4 }) readonly sizeXL!: number;
    @Prop({ type: Number, default: 6 }) readonly sizeMD!: number;
    @Prop({ type: Number, default: 6 }) readonly sizeSM!: number;
    @Prop({ type: Number, default: 6 }) readonly sizeMain!: number;
    @Prop({ type: Boolean, default: true }) readonly isClearable!: boolean;
    @Prop({ type: Boolean, default: false }) readonly needTooltip!: boolean;
    @Prop({ type: Object, required: false }) readonly emptyConditions!: object;
    @Prop({ type: [Array, Object], required: true }) filters!: IFilter[] | CreatedFilters;
    @Prop({ type: Number }) limit!: number;

    searchTimerId = 0;
    notFoundIds: string[] = [];
    isShowModal: boolean = false;
    // TODO временное решение , чтобы не править сразу на всех страницах
    classFilters: CreatedFilters | any;

    clearModal(): void {
        this.isShowModal = false;
        this.notFoundIds = [];
    }

    created(): void {
        // добавлено объявление класса здесь, пока все страницы не приведены к общему формату передачи фильтров
        if (Array.isArray(this.filters)) {
            this.classFilters = new CreatedFilters(this.filters);
        } else {
            this.classFilters = this.filters;
        }

        this.classFilters.parseRoute();

        this.classFilters.filters.forEach((filter) => {
            if (filter.autocomplete) {
                if (filter.isCountryCatalogue) {
                    filter.items = this.countries;
                    filter.select = this.countries.find((i) => i[filter.itemValue!] === this.$route.query[filter.id]) || '';
                    return;
                }
                filter.loading = false;
            }
        });
        // обновление урла при загрузке страницы
        // добавление офсета и фильтров, включая дефолтные
        // можно переопределить эти фильтры на страницах
        this.classFilters.updateStartUrl();
    }

    submit(): void {
        // отправка выбранных фильтров
        // можно переопределять на странице
        this.classFilters.submitFilter();
        this.$emit('submit', 0, this.limit);
    }

    clearAllFilters(): void {
        this.classFilters.clearFilters();
        this.$emit('clear');
    }

    // отсроченные запросы на получение данных для селекта
    private debounceQuerySelections(filterID: string) {
        const filter: IFilter = this.classFilters.filters.find((f: IFilter) => f.id === filterID);
        const value = filter.autocompleteValue;
        if (typeof value !== 'string') return;
        const collection = value.trim().split(/[ ,]+/);
        if (filter.apiMethod) {
            this.setDataWithApi(collection, filter, value);
        } else if (filter.multiPaste && collection.length > 1) {
            collection.forEach(value => this.setLoadedMultiDataToAutocomplete(filter, value));
            this.notFoundIds.length > 0 && (this.isShowModal = true);
        }
    }

    private setDataWithApi(collection: string[], filter: IFilter, value: string): void {
        clearTimeout(this.searchTimerId);
        const emailRegex = new RegExp('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$');
        const isCollectionOfEmails = collection.every(item => emailRegex.test(item));
        const isCollectionOfNumbers = collection.every((item) => !isNaN(Number(item)));
        const isMultiPaste = filter.multiPaste && collection.length > 1 && (isCollectionOfNumbers || isCollectionOfEmails);
        this.searchTimerId = window.setTimeout(async () => {
            if (isMultiPaste) {
                await this.getDataSelectMultiplePaste(collection, filter);
            } else {
                await this.getDataSelectManualInput(value, filter);
            }
        }, SEARCH_VALUE_DEBOUNCE); /* 850ms задержка */
        filter.loading = true;
    }

    private async getDataSelectMultiplePaste(collection: string[], filter: IFilter): Promise<void> {
        for (const item of collection) {
            const params = { [filter.key || 'query']: item };
            await this.getDataForSelect(filter, params, item, true);
        }
        if (this.notFoundIds.length > 0) {
            this.isShowModal = true;
        }
        filter.autocompleteValue = this.notFoundIds.join(', ');
        filter.loading = false;
    }

    private async getDataSelectManualInput(value: string, filter: IFilter): Promise<void> {
        const params = filter.customParams ?
            { ...filter.customParams(filter, value) } :
            { [filter.key || 'query']: filter.queryValue ? filter.queryValue(value) : value };
        await this.getDataForSelect(filter, params, value);
        filter.loading = false;
    }

    // запросы на получение данных для селекта
    private async getDataForSelect(filter: IFilter, params: { [key: string]: string }, value: string, isMultiPaste = false): Promise<void> {
        if (!filter) return;
        try {
            const result = await filter.apiMethod(params);
            // формирование Items для селектов
            // можно переопределять на каждой странице
            this.classFilters.getDataForAllFilterSelect(filter, result, filter.id);
            if (filter.multiPaste && isMultiPaste) {
                this.setMultiDataToAutocomplete(filter, value);
            } else {
                this.notFoundIds = [];
            }
        } catch (err) {
            showServerError(err, 'Список не загружен');
        }
        filter.loading = false;
    }

    private setMultiDataToAutocomplete(filter: IFilter, value: string): void {
        const key = this.getKey(filter, value);
        let items = JSON.parse(JSON.stringify(filter.items));
        items = xorBy(items, filter.select, key);
        if (items.length > 1) {
            items = items.filter((i: any) => String(i[key]).includes(value));
        }
        if (items.length === 0) {
            this.notFoundIds = this.notFoundIds.includes(value) ? this.notFoundIds : [...this.notFoundIds, value];
        }
        if (filter.multiPaste && items.length === 1) {
            items = uniqBy([...filter.select, items[0]], key);
            filter.items = JSON.parse(JSON.stringify(items));
            filter.select = items;
        }
    }

    private setLoadedMultiDataToAutocomplete(filter: IFilter, value: string): void {
        const key = this.getKey(filter, value);
        const item = filter.items!.find((item: any) => String(item[key]).includes(value));
        if (item) {
            filter.select = uniqBy([...filter.select, item], key);
        } else if (!item) {
            this.notFoundIds = this.notFoundIds.includes(value) ? this.notFoundIds : [...this.notFoundIds, value];
        }
    }

    private getKey(filter: IFilter, value: string): string {
        const isNumber = !isNaN(Number(value));
        return  filter.alsoSearchBy ? filter.alsoSearchBy : isNumber ? filter.itemValue : filter.itemText;
    }

    // очистить фильтр (date-range или date-picker)
    clearDateRangeFilter(filter: IFilter): void {
        filter.clear();
        const filters = { ...this.getFiltersParamsObject };
        delete filters[`${filter.id}_from`];
        delete filters[`${filter.id}_to`];
        this.setFiltersParamsObject(filters);
        this.classFilters.updateQueryString(filters);
        this.$emit('clear-date', filter);
    }
}
