
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { IFilter } from "@/types";
import SvgArrow from "@/assets/icons/arrow-down.svg";
import { COMPARE_OPERATORS } from "@/services/filters/classes";

@Component({
    components: { SvgArrow },
})
export default class CompareFilter extends Vue {
    @Prop({ required: true }) filter!: IFilter;
    @Ref() firstValue!: any;

    key = 0;
    compareType: string | COMPARE_OPERATORS = COMPARE_OPERATORS.GT;
    types = [
        { text: '>', value: COMPARE_OPERATORS.GT },
        { text: '≥', value: COMPARE_OPERATORS.GTE },
        { text: '<', value: COMPARE_OPERATORS.LT },
        { text: '≤', value: COMPARE_OPERATORS.LTE },
        { text: '↔', value: COMPARE_OPERATORS.BETWEEN },
    ];

    get isBetween(): boolean {
        return this.filter.operator === COMPARE_OPERATORS.BETWEEN;
    }

    mounted(): void {
        if (Array.isArray(this.filter.select)) return;
        this.filter.select = [];
    }

    clearInput(): void {
        this.$nextTick(() => this.delNulls());
    }

    updateType(type: COMPARE_OPERATORS): void {
        const isBetweenBefore = this.isBetween;
        this.filter.operator = type;

        if (isBetweenBefore) {
            this.filter.select = [];
        }
        if (this.isBetween) {
            this.firstValue.isFocused = true;
        }
    }

    getValue(value: string): number | null {
        return !value ? null : Number(value.replace(/[^0-9]/g, ''));
    }

    delNulls(): void {
        this.$nextTick(() => this.filter.select = this.filter.select.filter(i => i !== null));
    }

    updateFirstValue(event: InputEvent): void {
        const target = event.target as HTMLInputElement;
        this.updateValue(0, target.value);
    }
    updateSecondValue(event: InputEvent): void {
        const target = event.target as HTMLInputElement;
        this.updateValue(1, target.value);
    }

    updateValue(index: number, value: string): void {
        this.filter.select[index] = this.getValue(value);
        this.delNulls();
    }

    updateValueOnBlur(): void {
        if (!this.filter.select[1]) return;
        if (this.filter.select[1] <= this.filter.select[0]) {
            this.filter.select[1] = this.filter.select[0] + 1;
            this.key++;
        }
    }
}
