
import { Component, Ref, Vue } from 'vue-property-decorator';
import { Getter, Mutation } from 'vuex-class';
import SvgApplication from '@/assets/icons/nav-bar/application.svg';
import StatsCard from '@/components/base/StatsCard.vue';
import { getReInitiate, getResendInitiate } from '@/api/uapi';
import { showServerError } from '@/utils';
import ApplicationFilters from "@/components/uapi/analytics/ApplicationFilters.vue";
import { eventBus } from '@/eventbus';
import omit from "lodash-es/omit";
import isArray from "lodash-es/isArray";

@Component({
    components: {
        ApplicationFilters,
        SvgApplication,
        StatsCard,
    },
})
export default class ResendRequests extends Vue {
    @Mutation('SET_DEFAULT_FILTERS_PARAMS_OBJECT') setDefaultFiltersParamsObject;
    @Mutation('SET_FILTERS_PARAMS_OBJECT') setFiltersParamsObject;
    @Getter('GET_FILTERS_PARAMS_OBJECT') getFiltersParamsObject;
    @Ref('filters') filters!: ApplicationFilters;

    fields = [
        {
            label: "ID партнёра",
            field: "affiliate_id",
            analytic: "autocomplete",
        },
        {
            label: "Период (oт)",
            field: "created_from",
            type: "date-time-picker",
        },
        {
            label: "Период (до)",
            field: "created_to",
            type: "date-time-picker",
        },
        {
            label: "Сумма",
            field: "amount",
            analytic: "range",
            type: "integer",
        },
        {
            label: "Срок",
            field: "term",
            analytic: "range",
            type: "integer",
        },
        {
            label: "Дата рождения",
            field: "birth_date",
            analytic: "range",
            type: "date",
        },
        {
            label: "Страна проживания",
            field: "country",
            analytic: "multiselect",
            type: "string",
            choices: [
                "RU",
                "UA",
                "KZ",
                "ES",
            ],
        },
        {
            label: "Регион проживания",
            field: "region",
            analytic: "autocomplete",
            type: "string",
            url: "/v1/geo/regions",
        },
        {
            label: "Город проживания",
            field: "city",
            analytic: "autocomplete",
            type: "string",
            url: "/v1/geo/cities",
        },
        {
            label: "Включая ранее переотправленные",
            field: "force",
            analytic: "is_exist_bool",
            type: "bool",
        },
        {
            label: "Sub ID 1",
            field: "subid1",
            type: "string",
        },
        {
            label: "Sub ID 2",
            field: "subid2",
            type: "string",
        },
        {
            label: "Sub ID 3",
            field: "subid3",
            type: "string",
        },
        {
            label: "Sub ID 4",
            field: "subid4",
            type: "string",
        },
        {
            label: "Sub ID 5",
            field: "subid5",
            type: "string",
        },
    ];
    settings = {
        title: 'Переотправка заявок',
        loading: false,
    };
    countries: any[] = [];
    previousFilters: any = {};
    count: string | number = 0;
    stream: string | number = '';
    disabled = false;
    uuids: string = '';

    async created(): Promise<void> {
        eventBus.$on('change-disabled', this.disabledSubmit);
    }

    mounted(): void {
        setTimeout(() => {
            this.setCountries();
        }, 1000);
    }

    beforeDestroy(): void {
        eventBus.$off('change-disabled', this.disabledSubmit);
    }

    disabledSubmit(value: boolean): void {
        this.disabled = !value;
    }

    setCountries(): void {
        const filters = this.filters.filters;
        const countries = filters.find(f => f.id === 'country');
        if (countries) this.countries = countries.items!;
    }

    async submitFilters(resubmit?: boolean): Promise<void> {
        try {
            const params: { [key: string]: any } = {};
            const data: { [key: string]: any } = {};
            const filters = { ...this.getFiltersParamsObject };
            for (const key in filters) {
                if (filters[key] instanceof Array && !filters[key].length) {
                    continue;
                } else if (typeof filters[key] === 'boolean' && filters[key] === false) {
                    continue;
                }
                if (key === 'country' && filters[key]) {
                    if (!data.hasOwnProperty('geo')) {
                        data['geo'] = {};
                    }

                    if (!data['geo'].hasOwnProperty('country_id')) {
                        data['geo']['country_id'] = null;
                    }

                    data['geo']['country_id'] = isArray(filters[key])
                        ? filters[key].map((fl) => fl.toString())
                        : [filters[key].toString()];
                    console.log(data['geo']['country_id']);
                } else if (key === 'city' && filters[key]) {
                    if (!data.hasOwnProperty('geo')) {
                        data['geo'] = {};
                    }
                    if (!data['geo'].hasOwnProperty('city_id')) {
                        data['geo']['city_id'] = null;
                    }
                    data['geo']['city_id'] = isArray(filters[key])
                        ? filters[key].map((fl) => fl.toString())
                        : filters[key].toString();
                } else if (key === 'region' && filters[key]) {
                    if (!data.hasOwnProperty('geo')) {
                        data['geo'] = {};
                    }
                    if (!data['geo'].hasOwnProperty('region_id')) {
                        data['geo']['region_id'] = [];
                    }
                    data['geo']['region_id'] = filters[key].map((fl) =>
                        fl.id !== undefined
                            ? fl.id
                            : typeof fl === 'object'
                                ? fl.name.split(' ')[0]
                                : fl.toString(),
                    );
                } else {
                    if (key === 'created_to' || key === 'created_from') {
                        if (!!filters[key]) params[key] = filters[key].replace(' ', '_');
                    } else {
                        data[key] = filters[key];
                    }
                }
            }
            if (!params.hasOwnProperty('created_from')) {
                const date = new Date();
                const year = date.getFullYear();
                const month = ("0" + (date.getMonth() + 1)).slice(-2);
                const day = ("0" + date.getDate()).slice(-2);
                const hour = ("0" + date.getHours()).slice(-2);
                const minute = ("0" + date.getMinutes()).slice(-2);
                params['created_from'] = `${year}-${month}-${day}_${hour}:${minute}`;
                params['created_to'] = `${year}-${month}-${day}_${hour}:${minute}`;
            }
            const propertiesToAdd = [
                "amount",
                "term",
                "affiliate_id",
                "birth_date",
                "geo.country_id",
                "geo.region_id",
                "geo.city_id",
            ];

            propertiesToAdd.forEach(property => {
                let currentObj = data;
                const propertyParts = property.split(".");
                propertyParts.forEach((part, index) => {
                    if (!currentObj.hasOwnProperty(part)) {
                        currentObj[part] = {};
                    }
                    if (index === propertyParts.length - 1 && !currentObj[part]) {
                        currentObj[part] = [];
                    }
                    currentObj = currentObj[part];
                });
            });

            function replaceEmptyObjectsWithArrays(obj) {
                for (const key in obj) {
                    if (obj.hasOwnProperty(key)) {
                        const value = obj[key];
                        if (typeof value === "object" && !Array.isArray(value)) {
                            replaceEmptyObjectsWithArrays(value);
                            if (Object.keys(value).length === 0) {
                                obj[key] = [];
                            }
                        }
                    }
                }
            }

            replaceEmptyObjectsWithArrays(data);
            data['affiliate_id'] = Array.from(new Set(data['affiliate_id'].filter((item) => item !== '').map(Number)));
            data['term'].includes(null) ? data['term'] = [] : true;
            data['amount'].includes(null) ? data['amount'] = [] : true;
            if (!!data.geo['country_id']) {
                data.geo['country_id'] = this.countries.filter((item) => data.geo['country_id'].includes(item.isoCode)).map((value) => value.id.toString());
            }
            data['birth_date'] = !!data['birth_date_from'] ? [data['birth_date_from'], data['birth_date_to']] : [];
            if (!data.hasOwnProperty('force')) data.force = false;
            if (resubmit) {
                if (this.uuids.trim() !== '') {
                    const uuidsArray = this.uuids.split(/[\s,.\n]+/).filter(uuid => uuid.trim() !== '');
                    const result = await getResendInitiate({}, { stream_id: this.stream, uuids: uuidsArray });
                    this.count = result.data.count || 0;
                    this.uuids = '';
                    return;
                }
                data.stream_id = this.stream;
                const result = await getResendInitiate(params, { ...omit(data, ['birth_date_from', 'birth_date_to']) });
                this.count = result.data.count || 0;
            } else {
                const result = await getReInitiate(params, { ...omit(data, ['birth_date_from', 'birth_date_to']) });
                this.count = result.data.count;
            }
            this.previousFilters = filters;
        } catch (err) {
            showServerError(err);
        }
    }


    clearFilters(): void {
        this.setDefaultFiltersParamsObject({
            created_from: this.getFiltersParamsObject['created_from'],
            created_to: this.getFiltersParamsObject['created_to'],
        });
        this.setFiltersParamsObject({});
        this.submitFilters();
    }
}
