var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('span',{staticClass:"base__text",class:{
        'is-line-clamp': _setup.props.lineClamp,
        'is-ellipsis': _setup.props.isEllipsis,
        'is-underline': _setup.props.isUnderline,
    },style:({
        'max-width': _setup.props.maxWidth,
        '-webkit-line-clamp': _setup.props.lineClamp,
    })},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }