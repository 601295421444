
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import BaseCard from "@/components/base/BaseCard.vue";
import { SurveyQuestion } from "@/services/surveys/SurveyQuestion";
import SvgText from '@/assets/icons/survey/text.svg';
import SvgRadio from '@/assets/icons/survey/radio.svg';
import SvgCheckbox from '@/assets/icons/survey/checkbox.svg';
import TrashBtn from "@/components/base/buttons/TrashBtn.vue";
import { getNumEnding } from "@/utils";
import TableWithFrontPaginationDesign from "@/components/base/table/TableWithFrontPaginationDesign.vue";
import { colorsDonutChart } from "@/plugins/vue-apex-chart";

require('@/plugins/vue-apex-chart.ts');

@Component({
    components: {
        TableWithFrontPaginationDesign,
        TrashBtn,
        BaseCard, SvgText, SvgRadio, SvgCheckbox,
    },
})

export default class Question extends Vue {
    @Prop({ default: {} }) question!: SurveyQuestion;
    @Prop({ default: 0 }) index!: number;

    @Ref() chart!: ApexCharts;

    // варианты концовок
    ends = [ 'голос', 'голоса', 'голосов' ];
    textAnswerEnds = ['ответ', 'ответа', 'ответов'];

    routes = [
        {
            routeParam: 'id',
            extraRouteParamName: 'account_id',
            slot: 'item.account_id',
            routerName: 'affiliate',
            text: 'account_id',
            tooltip: (item: any): string => item.account_id,
        },
    ];

    answerHeaders= [
        { text: 'ID', sortable: false, value: 'account_id' , align: 'center', width: '100px' },
        { text: 'ФИО', sortable: false, value: 'name', width: '300px' },
        { text: 'Менеджер', sortable: false, value: 'manager_name', width: '300px' },
        { text: 'Email', sortable: false, value: 'email' },
        { text: 'Ответ', sortable: false, value: 'text' },
    ];

    heightLegend = '100%';

    selectedAnswerIndex: number = 0;

    getHeightLegend(): void {
        if ((this.$refs.chart! as Vue)?.$el  as Element) {
            const legend = ((this.$refs.chart! as Vue).$el! as Element)!.querySelector('.apexcharts-legend');
            const header = (this.$refs.header as Element)!.clientHeight;
            this.heightLegend = (String(legend!.scrollHeight + header + 30) + 'px') || '100%';
        } else {
            this.heightLegend = '100%';
        }
    }

    clickOnChart(index: number): void {
        this.selectedAnswerIndex = index;
    }

    mounted(): void {
        this.getHeightLegend();
        window.addEventListener("resize", this.getHeightLegend);
    }

    get sections():  {label: string, value: number}[] {
        const sections: {label: string, value: number}[] = [];
        this.question.answers.forEach((a) => {
            sections.push({ label: a.text || '', value: a.count || 0 });
        });
        if (this.question.isVariantAndText) {
            sections.push({ label: 'Свой ответ', value: this.question.text_answers!.length });
        }

        return sections;
    }

    get getTableHeaders(): any {
        if ((this.question.isVariantAndText && this.selectedAnswerIndex === this.sections.length - 1) || this.question.isTextType) {
            return this.answerHeaders;
        } else {
            return this.answerHeaders.slice(0, -1);
        }
    }

    get getTableTitle(): string {
        const selected = this.sections[this.selectedAnswerIndex!];
        return selected?.label!;
    }

    get getTableData(): any {
        const selected = this.question.answers[this.selectedAnswerIndex!];
        return selected?.users! || this.question.text_answers!;
    }

    formatter(val: string, opts: any): string {
        const answer = this.question.answers[opts.seriesIndex];
        const text_answers = this.question.text_answers;
        if (!!answer) {
            return val + " (" + answer.count + ' ' + getNumEnding(answer.count || 0, this.ends) + ")";
        } else if (!!text_answers) {
            return val + " (" + text_answers.length + ' ' + getNumEnding(text_answers.length, this.textAnswerEnds) + ")";
        } else return val;
    }

    get chartOptions(): any {
        return {
            chart: {
                type: 'donut',
                events: {
                    dataPointSelection: (event, chartContext, config) => {
                        this.clickOnChart(config.dataPointIndex);
                    },
                },
            },
            stroke: {
                show: true,
                curve: 'smooth',
                lineCap: 'butt',
                colors: colorsDonutChart,
                width: 2,
                dashArray: 0,
            },
            plotOptions: {
                pie: {
                    expandOnClick: false,
                    dataLabels: {
                        offset: 40,
                    },
                    startAngle: -30,
                    endAngle: 330,
                    size: 200,
                },
            },
            dataLabels: {
                style: {
                    colors: ['#212121'],
                    fontSize: '14px',
                    fontWeight: 500,
                },
                dropShadow: {
                    enabled: false,
                },
            },
            colors: colorsDonutChart,
            labels: this.sections.map(i => i.label),
            legend: {
                position: 'right',
                offsetY: 0,
                horizontalAlign: 'center',
                fontSize: '14px',
                fontFamily: 'Inter',
                fontWeight: 500,
                formatter: (val, opts) => {
                    return this.formatter(val, opts);
                },
            },
            fill: {
                opacity: 0.5,
            },
            states: {
                hover: {
                    filter: 'none',
                },
            },
            tooltip: {
                enabled: false,
            },
        };
    }

    getNumEnding = getNumEnding;
    beforeDestroy(): void {
        window.removeEventListener("resize", this.getHeightLegend);
    }
}

