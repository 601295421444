
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import { OfferSingle } from "@/services/offers/OfferSingle";
import OfferCard from "@/components/offers/offer/OfferCard.vue";
import { eventBus } from "@/eventbus";
import Status from "@/components/base/Status.vue";
import SvgPrivateOffer from "@/assets/icons/private-offer.svg";
import BaseSelect from "@/components/base/design/BaseSelect.vue";
import { IEditableOfferGoalFields, IOfferGoal } from "@/api/types/offers";
import { translateBoolean, translateGoalStatus, translateOffersStatus } from "@/utils/translate";
import DateTimePickerNew from "@/components/base/pickers/DateTimePickerNew.vue";

interface IHeaderOffer {
    name: string;
    value: any;
    type: string;
}

@Component({
    methods: { translateBoolean },
    components: {
        DateTimePickerNew,
        BaseSelect,
        SvgPrivateOffer,
        Status,
	    OfferCard,
    },
})
export default class GoalMain extends Vue {
    @Prop({ required: true }) offer!: OfferSingle | null;
    @Prop({ required: true }) goal!: IOfferGoal;
    @PropSync('isLoading') isLoadingSync!: boolean;
    translateOffersStatus = translateOffersStatus;
    translateGoalStatus = translateGoalStatus;
    statusItems = [{ id: true }, { id: false } ];
    protocolItems = [{ id: 'postback', text: 'Постбэк' }, { id: 'pixel', text: 'Пиксель' } ];
    protocolKey = 0;
    isEdit = false;
    offerGoalData: IEditableOfferGoalFields = {
        active: false,
        action_date: '',
    };

    get getOptions(): IHeaderOffer[] {
        const main = [
            { name: 'Название оффера', value: `${this.offer?.legacy_id} ${this.offer?.name}`, type: 'offer-title' },
            { name: 'Статус оффера', value: this.offer?.status, type: 'offer-status' },
            { name: 'Статус цели', value: this.goal.active, type: 'status' },
            { name: 'Название цели', value: this.goal.name, type: 'title' },
            { name: 'Способ трекинга', value: this.goal.protocol, type: 'protocol' },
            { name: 'Автоподт. конверсии', value: !this.goal.approve_conversions, type: 'approve_conversions' },
            { name: 'Приватность', value: this.goal.private, type: 'private' },
            { name: 'Эндпоинт', value: this.goal.endpoint, type: 'endpoint' },
        ];

        const actionDate = { name: 'Начало действия', value: this.offerGoalData.action_date, type: 'action_date' };
        return this.isEdit ? [...main, actionDate] : main;
    }

    get getStatusItems(): { id: boolean, text: string }[] {
        return this.statusItems.map(i => {
            return { id: i.id, text: translateGoalStatus(i.id).text };
        });
    }

    editInfo(edit: boolean): void {
        if (edit) {
            this.setDefaults();
        } else {
            this.updateAllOfferGoalData();
        }
        this.isEdit = edit;
    }

    cancelEdit(): void {
        this.isEdit = false;
    }

    setDefaults(): void {
        this.offerGoalData.name = this.goal.name;
        this.offerGoalData.active = this.goal.active;
        this.offerGoalData.endpoint = this.goal.endpoint;
        this.offerGoalData.private = this.goal.private;
        this.offerGoalData.approve_conversions = !this.goal.approve_conversions;
        this.offerGoalData.protocol = this.goal.protocol;
    }

    async updateAllOfferGoalData(): Promise<void> {
        this.isLoadingSync = true;
        const isOfferGoalDataSaved = await this.offer?.editGoal({ ...this.goal, ...this.offerGoalData });
        if (isOfferGoalDataSaved) {
            delete this.offerGoalData.action_date;
            eventBus.$emit("update-goal");
        }
        this.isLoadingSync = false;
    }
}
